import React, { useState, useEffect } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import { useAlert } from "../../Context/Alert.Provider"
import Buttons from "../Global/Buttons"
// import { Search } from "tabler-icons-react"

const StockItemForm = (props) => {
	// Get the custom hook from Alert.Provider.js
	const newAlert = useAlert()

	let { stockItemId } = useParams()

	const [newStockItem, setNewStockItem] = useState({})
	const [errorOpenFood, setErrorOpenFood] = useState(false)

	useEffect(() => {
		// If edition mode, fetch stockitem data with id
		if (stockItemId) {
			const url = `${process.env.REACT_APP_API_URL}Stocks/GetStockItems.php`

			const api = axios.create()
			api.post(url, { id: stockItemId }).then((response) => {
				console.log(response.data)
				setNewStockItem(response.data[0])
			})
		}
	}, [stockItemId])

	// Get all current change from inputs
	const handleChange = (e) => {
		e.target.name
			? setNewStockItem({ ...newStockItem, [e.target.name]: e.target.value })
			: setNewStockItem({ ...newStockItem, [e.target.dataset.name]: e.target.dataset.value })
	}

	// Send new stockitem data to controller
	const sendStockItem = (e) => {
		e.preventDefault()

		const api = axios.create()
		// If edit mode, use EditStockItem controller else use AddStockItem controller
		let url = stockItemId ? `${process.env.REACT_APP_API_URL}Stocks/EditStockItem.php` : `${process.env.REACT_APP_API_URL}Stocks/AddStockItem.php`
		api.post(url, newStockItem).then((response) => {
			props.getNewStockItem && props.getNewStockItem(response.data.newStockItem[0])
			newAlert({
				type: "success",
				message: `Le produit <b>${newStockItem.name}</b> a bien été ${stockItemId ? "modifié" : "ajouté"}`,
			})
			// props.history.push(`/stocks`)
		})
	}

	// Call Open Food API with barcode variable
	const handleSearchBarcode = (e) => {
		e.preventDefault()
		const api = axios.create()
		let url = `https://fr.openfoodfacts.org/api/v0/produit/${newStockItem.barcode}.json`
		api.post(url).then((response) => {
			response.data.status === 1
				? setNewStockItem({ ...newStockItem, name: response.data.product.product_name_fr, img_url: response.data.product.image_url })
				: setErrorOpenFood(true)
		})
	}

	return (
		<section className={`${props.isInclude ? "" : "section size-3"}`}>
			<header className="header">
				<h2>{stockItemId ? `Éditer : ${newStockItem.name}` : "Nouveau produit"}</h2>
				<div className="header-actions">
					<Buttons size="huge" type="full" color="main" icon="save" iconSize={20} iconColor="white" handleClick={(e) => sendStockItem(e)}>
						{stockItemId ? "Sauvegarder" : "Ajouter"}
					</Buttons>
				</div>
			</header>
			<form className="form stockitem-form" onSubmit={(e) => sendStockItem(e)} autoComplete="off">
				<section className="col">
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Code barre</p>
							<div className="field-input-button">
								<label htmlFor="">
									<input
										type="text"
										className="field"
										name="barcode"
										value={newStockItem.barcode || ""}
										onChange={(e) => handleChange(e)}
									/>
								</label>
								<Buttons
									handleClick={(e) => handleSearchBarcode(e)}
									size="huge"
									type="full"
									color="grey"
									icon="search"
									iconSize="20"
									iconColor="#393e4a"
									iconOnly={true}
									customClassName={"square"}></Buttons>
								{/* <button onClick={(e) => handleSearchBarcode(e)}>
									<Search size={20} color={"#393e4a"} />
								</button> */}
							</div>
							{errorOpenFood && <p>Produit non trouvé</p>}
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper field-price">
							<p className="field-label">Nom</p>
							<input type="text" className="field" name="name" value={newStockItem.name || ""} onChange={(e) => handleChange(e)} />
						</div>
					</article>
					{newStockItem.img_url && (
						<article className="row">
							<div className="field-wrapper">
								<img src={newStockItem.img_url} alt="" />
								<p onClick={() => setNewStockItem({ ...newStockItem, img_url: null })}>Supprimer l'image</p>
							</div>
						</article>
					)}
				</section>
			</form>
		</section>
	)
}

export default StockItemForm
