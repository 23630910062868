import React, { useState, useEffect } from "react"
import axios from "axios"
import Buttons from "../Global/Buttons"
import ProductItem from "./ProductItem"
import SearchBar from "../Global/SearchBar"
import { useAlert } from "../../Context/Alert.Provider"

const ProductsList = () => {
	const newAlert = useAlert()
	const [products, setProducts] = useState([])
	const [searchProducts, setSearchProducts] = useState(localStorage.getItem("searchProductQuery") || "")

	// Get all products data
	const getProducts = (search) => {
		const url = `${process.env.REACT_APP_API_URL}Products/GetProducts.php`

		const api = axios.create()
		api.get(url).then((response) => {
			const filteredRes = response.data?.filter((item) => item.name_fr.toLocaleLowerCase().indexOf(search) !== -1)
			setProducts(filteredRes)
		})
	}

	const handleSearch = (e) => {
		const searchQuery = e.target?.value || ""
		localStorage.setItem("searchProductQuery", searchQuery)
		setSearchProducts(searchQuery)
	}

	const handleCopy = (e) => {
		const copiedProduct = { ...e, name_fr: e.name_fr + "_COPY", status: 2 }

		const api = axios.create()
		// If edit mode, use EditProduct controller else use AddProduct controller
		let url = `${process.env.REACT_APP_API_URL}Products/AddProduct.php`
		api.post(url, copiedProduct).then((response) => {
			newAlert({
				type: "success",
				message: `Le produit <b>${copiedProduct.name_fr}</b> a bien été copié`,
			})
			setProducts([...products, response.data.$_POST])
		})
	}

	const handleUpdateStatus = (e) => {
		// Update products list
		const newStatus = e.status === "1" ? "2" : "1"
		const newProductLists = products.map((p) => {
			if (p.id === e.id) {
				p.status = newStatus
			}
			return p
		})
		setProducts(newProductLists)

		// Send edited product to database
		const api = axios.create()
		let url = `${process.env.REACT_APP_API_URL}Products/EditProduct.php`
		api.post(url, { ...e, status: newStatus, action: "updateStatus" }).then((response) => {})
	}

	useEffect(() => {
		getProducts(searchProducts)
	}, [searchProducts])

	return (
		<section className="section size-6">
			<header className="header sticky">
				<h2>Produits</h2>
				<div className="header-actions">
					<SearchBar
						onChange={(e) => handleSearch(e)}
						clearSearch={() => handleSearch("")}
						Placeholder="Rechercher"
						Value={searchProducts}
					/>
					<Buttons path="/products/new" size="huge" type="full" color="main" icon="plus" iconSize="20" iconColor="white">
						Ajouter
					</Buttons>
				</div>
			</header>
			{products ? (
				<div className="table tab-product">
					<div className="tab-row tab-header">
						<div className="tab-col">ID</div>
						<div className="tab-col">Nom</div>
						<div className="tab-col">Catégorie</div>
						<div className="tab-col">Prix</div>
						<div className="tab-col">Pièce</div>
						<div className="tab-col">Actions</div>
					</div>
					{products?.map((item) => (
						<ProductItem key={item.id} {...item} handleUpdateStatus={(e) => handleUpdateStatus(e)} handleCopy={(e) => handleCopy(e)} />
					))}
				</div>
			) : (
				<p className="empty-text">Aucun produit n'a été trouvé</p>
			)}
		</section>
	)
}

export default ProductsList
