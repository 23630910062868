import React from "react"
import { Link } from "react-router-dom"
import { Edit, X, Check } from "tabler-icons-react"
import Buttons from "../Global/Buttons"

const StockTakings = (props) => {

	return (
		<div className="tab-row">
			{props.editStockTaking.stocktaking_id === props.item.stocktaking_id ? (
				<div className="col tab-body stocktaking-edit">
					<input
						className="field"
						autoFocus
						name="name"
						type="text"
						value={props.editStockTaking.name}
						onChange={(e) => props.setEditStockTaking({ ...props.item, name: e.target.value })}
					/>
					<Buttons size="huge" type="full" color="main" iconOnly={true} handleClick={props.handleSubmitEditStockTaking}>
						<Check color={"#FFFFFF"} />
					</Buttons>
					<Buttons size="huge" type="full" color="grey" iconOnly={true} handleClick={(e) => props.setEditStockTaking({})}>
						<X color={"#8C8C8C"} />
					</Buttons>
				</div>
			) : (
				<Link to={`/stocks/${props.item.stocktaking_id}`}>
					<p className="col tab-body">{props.item.name}</p>
				</Link>
			)}

			<p className="col tab-body">{props.item.numberReference || 0}</p>
			<p className="col tab-body">{props.item.totalItem || 0}</p>
			<div className="col tab-body action">
				<Edit
					color={"#DEDEDE"}
					onClick={() =>
						props.editStockTaking.stocktaking_id === props.item.stocktaking_id
							? props.setEditStockTaking({})
							: props.setEditStockTaking({ ...props.item })
					}
				/>
			</div>
		</div>
	)
}

export const StockItems = (props) => {
	return (
		<div className="tab-row">
			<p className="col tab-body">{props.name}</p>
			<p className="col tab-body mono">{props.barcode}</p>
			<div className="col tab-body action">
				<Link to={`/stocks/item/edit/${props.stockitem_id}`}>
					<Edit color={"#DEDEDE"} />
				</Link>
			</div>
		</div>
	)
}

export default StockTakings
