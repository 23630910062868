import React, { useState, useEffect } from "react"
import Buttons from "../Global/Buttons"
import { Link } from "react-router-dom"
import axios from "axios"
import { ResponsiveContainer, ComposedChart, Bar, Line, XAxis, YAxis, Tooltip, Area } from "recharts"
import Moment from "react-moment"
import moment from "moment"
import NumberFormat from "react-number-format"
import { useMediaQuery } from "react-responsive"
import ReportingByMonth from "./ReportingByMonth"
import BackButton from "../Global/BackButton"
import Loader from "../Global/Loader"

const ReportingDashboard = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
	const [revenuesAllTimeGraph, setRevenuesAllTimeGraph] = useState([])
	const [lastTwoMonth, setLastTwoMonth] = useState([])
	const [allRevenue, setAllRevenue] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	const getRevenues = () => {
		const url = `${process.env.REACT_APP_API_URL}Reporting/GetRevenue.php`

		const api = axios.create()
		api.get(url).then((response) => {
			setRevenuesAllTimeGraph(response.data.allTimeGraph)
			const tempAllRevenue = Object.entries(response.data.allRevenue).map((year) => {
				year[1].byMonth = []
				Object.entries(year[1].tempByMonth).map((month) => {
					return year[1].byMonth.push(month[1])
				})
				return year[1]
			})
			setAllRevenue(tempAllRevenue)
			setLastTwoMonth(
				Object.entries(response.data.lastTwoMonth)
					.map((month) => {
						return { year: month[0], months: month[1] }
					})
					.reverse()
			)
			setIsLoading(false)
		})
	}

	useEffect(() => {
		getRevenues()
	}, [])

	// Charts
	const monthTickFormatter = (tick) => {
		const date = new Date(tick)
		return String(date.getDate()).padStart(2, "0") + "/" + String(parseInt(date.getMonth()) + 1).padStart(2, "0")
	}

	function CustomTooltip({ payload, label, active }) {
		return active ? (
			<div className="rechart-tooltip">
				<p className="title">{moment(label).format("L")}</p>
				<p className="detail">CA : {payload[0].value}€</p>
			</div>
		) : null
	}

	function MonthlyTooltip({ payload, label, active }) {
		return active ? (
			<div className="rechart-tooltip">
				<p className="title">{moment(payload[0].payload.day).format("dddd DD/MM/YYYY")}</p>
				<p className="detail">CA : {payload[0].value}€</p>
			</div>
		) : null
	}

	return (
		<section className="section size-6">
			<header className="header sticky">
				<h2>{isMobile && <BackButton />} Bilan</h2>
				<div className="header-actions">
					<Buttons path="/reporting/insights" size="huge" type="full" color="main" icon="graph" iconSize="20" iconColor="white">
						Insights
					</Buttons>
					<Buttons path="/reporting/new" size="huge" type="full" color="main" icon="plus" iconSize="20" iconColor="white">
						Ajouter
					</Buttons>
				</div>
			</header>
			{isLoading ? (
				<Loader />
			) : (
				<>
					{/* Main charts over the year */}
					<ResponsiveContainer width="95%" height={200}>
						<ComposedChart data={revenuesAllTimeGraph} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
							<XAxis dataKey="day" stroke="#8c8c8c" tickFormatter={monthTickFormatter} />
							<YAxis dataKey="revenue" stroke="#8c8c8c" />
							<Tooltip content={<CustomTooltip />} />
							<Line type="monotone" dataKey="uv" stroke="#a75fa6" />
							<Area type="step" dataKey="revenue" stroke="none" fill="#a75fa6" />
						</ComposedChart>
					</ResponsiveContainer>
					{/* Month */}
					{
						lastTwoMonth.map((data) =>
							Object.keys(data.months)
								.reverse()
								.map((month, key) => (
									<div className="revenue-month" key={key}>
										<h3>
											<Moment format="MMMM YYYY" date={`${data.year}/${month}`} />
										</h3>
										{/* Monthly global data charts */}
										<div className="revenue-month-global">
											<div className="revenue-month-global-graph">
												<ResponsiveContainer width="100%" height={100}>
													<ComposedChart data={data.months[month].byDate} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
														<Tooltip content={<MonthlyTooltip />} />
														<Bar dataKey="revenue" fill="#a75fa6" maxBarSize={25} />
													</ComposedChart>
												</ResponsiveContainer>
											</div>
										</div>
										<div className="table revenue-tab">
											<div className="tab-row tab-header">
												<div className="tab-col">Jour</div>
												<div className="tab-col align-right">CA</div>
												<div className="tab-col align-right">CB</div>
												<div className="tab-col align-right">ESP</div>
												<div className="tab-col align-right">TR</div>
												<div className="tab-col align-right">CHQ</div>
												<div className="tab-col align-right">Action</div>
											</div>
											{/* Month total */}
											<div className="tab-row tab-sumup">
												<p className="col tab-body mono">Total</p>
												<p className="col tab-body mono bold align-right">
													{isMobile && <span className="mobile-title">CA</span>}
													<NumberFormat
														value={data.months[month].totalRevenue || 0}
														decimalScale={2}
														displayType={"text"}
														decimalSeparator={","}
														fixedDecimalScale={true}
														suffix={"€"}
														thousandSeparator={" "}
													/>
													<i>
														<NumberFormat
															value={data.months[month].z_checkout || 0}
															decimalScale={2}
															displayType={"text"}
															decimalSeparator={","}
															fixedDecimalScale={true}
															thousandSeparator={" "}
														/>
														€ (
														<NumberFormat
															value={(data.months[month].z_checkout * 100) / data.months[month].totalRevenue || 0}
															decimalScale={0}
															displayType={"text"}
															decimalSeparator={","}
															fixedDecimalScale={true}
														/>
														%)
													</i>
												</p>
												<p className="col tab-body mono align-right">
													{isMobile && <span className="mobile-title">CB</span>}
													<NumberFormat
														value={data.months[month].subtotalCard}
														decimalScale={2}
														displayType={"text"}
														suffix={"€"}
														decimalSeparator={","}
														thousandSeparator={" "}
													/>
													<NumberFormat
														value={(data.months[month].subtotalCard * 100) / data.months[month].totalRevenue}
														decimalScale={2}
														displayType={"text"}
														renderText={(value) => <i>{value}%</i>}
														decimalSeparator={","}
													/>
												</p>
												<p className="col tab-body mono align-right">
													{isMobile && <span className="mobile-title">ESP</span>}
													<NumberFormat
														value={data.months[month].subtotalCash}
														decimalScale={2}
														displayType={"text"}
														suffix={"€"}
														decimalSeparator={","}
														thousandSeparator={" "}
													/>
													<NumberFormat
														value={(data.months[month].subtotalCash * 100) / data.months[month].totalRevenue}
														decimalScale={2}
														displayType={"text"}
														renderText={(value) => <i>{value}%</i>}
														decimalSeparator={","}
													/>
												</p>
												<p className="col tab-body mono align-right">
													{isMobile && <span className="mobile-title">TR</span>}
													<NumberFormat
														value={data.months[month].subtotalMealTicket}
														decimalScale={2}
														displayType={"text"}
														suffix={"€"}
														decimalSeparator={","}
														thousandSeparator={" "}
													/>
													<NumberFormat
														value={(data.months[month].subtotalMealTicket * 100) / data.months[month].totalRevenue}
														decimalScale={2}
														displayType={"text"}
														renderText={(value) => <i>{value}%</i>}
														decimalSeparator={","}
													/>
												</p>
												<p className="col tab-body mono align-right">
													{isMobile && <span className="mobile-title">CHQ</span>}
													<NumberFormat
														value={data.months[month].subtotalCheque}
														decimalScale={2}
														displayType={"text"}
														suffix={"€"}
														decimalSeparator={","}
														thousandSeparator={" "}
													/>
													<NumberFormat
														value={(data.months[month].subtotalCheque * 100) / data.months[month].totalRevenue}
														decimalScale={2}
														displayType={"text"}
														renderText={(value) => <i>{value}%</i>}
														decimalSeparator={","}
													/>
												</p>
												<div className="col tab-body action align-right"></div>
											</div>
											{/* Month average */}
											<div className="tab-row tab-sumup">
												<p className="col tab-body mono">Moyenne</p>
												<p className="col tab-body mono bold align-right">
													{isMobile && <span className="mobile-title">CA</span>}
													<NumberFormat
														value={data.months[month].totalRevenue / data.months[month].byDate.length || 0}
														decimalScale={2}
														displayType={"text"}
														decimalSeparator={","}
														fixedDecimalScale={true}
														suffix={"€"}
													/>
													<NumberFormat
														value={data.months[month].totalRevenue / moment(`${month}`).daysInMonth()}
														decimalScale={2}
														displayType={"text"}
														renderText={(value) => <i>{value}€</i>}
														decimalSeparator={","}
														fixedDecimalScale={true}
													/>
												</p>
												<p className="col tab-body mono align-right">
													{isMobile && <span className="mobile-title">CB</span>}
													<NumberFormat
														value={data.months[month].subtotalCard / data.months[month].byDate.length || 0}
														decimalScale={2}
														displayType={"text"}
														decimalSeparator={","}
														fixedDecimalScale={true}
														suffix={"€"}
													/>
												</p>
												<p className="col tab-body mono align-right">
													{isMobile && <span className="mobile-title">ESP</span>}
													<NumberFormat
														value={data.months[month].subtotalCash / data.months[month].byDate.length || 0}
														decimalScale={2}
														displayType={"text"}
														decimalSeparator={","}
														fixedDecimalScale={true}
														suffix={"€"}
													/>
												</p>
												<p className="col tab-body mono align-right">
													{isMobile && <span className="mobile-title">TR</span>}
													<NumberFormat
														value={data.months[month].subtotalMealTicket / data.months[month].byDate.length || 0}
														decimalScale={2}
														displayType={"text"}
														decimalSeparator={","}
														fixedDecimalScale={true}
														suffix={"€"}
													/>
												</p>
												<p className="col tab-body mono align-right">
													{isMobile && <span className="mobile-title">CHQ</span>}
													<NumberFormat
														value={data.months[month].subtotalCheque / data.months[month].byDate.length || 0}
														decimalScale={2}
														displayType={"text"}
														decimalSeparator={","}
														fixedDecimalScale={true}
														suffix={"€"}
													/>
												</p>
												<div className="col tab-body action align-right"></div>
											</div>
											{/* Day by day */}
											{/* Display day by day detail only for current month and the 3 last month */}
											{[...data.months[month].byDate].reverse().map((item) => (
												<div className="tab-row" key={item.id}>
													<p className="col tab-body mono">
														<Moment format="DD dddd" date={item.day} />
													</p>
													{/* CA */}
													<p className="col tab-body mono bold align-right">
														{isMobile && <span className="mobile-title">CA</span>}
														<NumberFormat
															value={item.revenue || 0}
															decimalScale={2}
															displayType={"text"}
															decimalSeparator={","}
															fixedDecimalScale={true}
															suffix={"€"}
														/>
														<i>
															<NumberFormat
																value={item.z_checkout || 0}
																decimalScale={2}
																displayType={"text"}
																decimalSeparator={","}
																fixedDecimalScale={true}
															/>
															€ (
															<NumberFormat
																value={(item.z_checkout * 100) / item.revenue || 0}
																decimalScale={0}
																displayType={"text"}
																decimalSeparator={","}
																fixedDecimalScale={true}
															/>
															%)
														</i>
													</p>
													{/* CB */}
													<p className="col tab-body mono align-right">
														{isMobile && <span className="mobile-title">CB</span>}
														<NumberFormat
															value={item.subtotal_card || 0}
															decimalScale={2}
															displayType={"text"}
															decimalSeparator={","}
															fixedDecimalScale={true}
															suffix={"€"}
														/>
														<NumberFormat
															value={(item.subtotal_card * 100) / item.revenue || 0}
															decimalScale={0}
															displayType={"text"}
															renderText={(value) => <i>{value}%</i>}
															decimalSeparator={","}
															fixedDecimalScale={true}
														/>
													</p>
													{/* ESP */}
													<p className="col tab-body mono align-right">
														{isMobile && <span className="mobile-title">ESP</span>}
														<NumberFormat
															value={item.cash_left + item.cash_out || 0}
															decimalScale={2}
															displayType={"text"}
															decimalSeparator={","}
															fixedDecimalScale={true}
															suffix={"€"}
														/>
														<NumberFormat
															value={((item.cash_left + item.cash_out) * 100) / item.revenue || 0}
															decimalScale={0}
															displayType={"text"}
															renderText={(value) => <i>{value}%</i>}
															decimalSeparator={","}
															fixedDecimalScale={true}
														/>
													</p>
													{/* TR */}
													<p className="col tab-body mono align-right">
														{isMobile && <span className="mobile-title">TR</span>}
														<NumberFormat
															value={item.subtotal_meal_ticket + item.subtotal_meal_card || 0}
															decimalScale={2}
															displayType={"text"}
															decimalSeparator={","}
															fixedDecimalScale={true}
															suffix={"€"}
														/>
														<NumberFormat
															value={((item.subtotal_meal_ticket + item.subtotal_meal_card) * 100) / item.revenue || 0}
															decimalScale={0}
															displayType={"text"}
															renderText={(value) => <i>{value}%</i>}
															decimalSeparator={","}
															fixedDecimalScale={true}
														/>
													</p>
													{/* CHQ */}
													<p className="col tab-body mono align-right">
														{isMobile && <span className="mobile-title">CHQ</span>}
														<NumberFormat
															value={item.cheque || 0}
															decimalScale={2}
															displayType={"text"}
															decimalSeparator={","}
															fixedDecimalScale={true}
															suffix={"€"}
														/>
														<NumberFormat
															value={(item.cheque * 100) / item.revenue || 0}
															decimalScale={0}
															displayType={"text"}
															renderText={(value) => <i>{value}%</i>}
															decimalSeparator={","}
															fixedDecimalScale={true}
														/>
													</p>
													<div className="col tab-body action align-right">
														<Link to={`/reporting/${item.id}`}>
															Détail
														</Link>
													</div>
												</div>
											))}
										</div>
									</div>
								))
						)}

					<ReportingByMonth data={allRevenue} />
				</>
			)}
		</section>
	)
}

export default ReportingDashboard
