import React from "react"
import { useNavigate } from "react-router-dom"
import { ArrowLeft } from "tabler-icons-react"

const BackButton = () => {
	let history = useNavigate()
	return (
		<button type="button" onClick={() => history(-1)}>
			<ArrowLeft size={30} color={"#393e4a"} />
		</button>
	)
}

export default BackButton
