import React from "react"
import { Link } from "react-router-dom"
// eslint-disable-next-line
import CustomerCity, { CustomerPayment } from "./CustomerStatics"
import { Edit, Location, LiveView } from "tabler-icons-react"

const CustomerCard = (props) => {
	// const payments = props.payment?.map((el) => CustomerPayment.find((e) => parseInt(el) === e.id))
	return (
		<div className="customer-card">
			<p className="cc-address">
				<strong>
					{props.address} {props.customer_name && " - " + props.customer_name}
				</strong>
				<span>{CustomerCity[props.city]}</span>
			</p>
			<div className="cc-buttons">
				<div className="cc-phones">
					{props.phone_1 && <a href={`tel:${props.phone_1}`}>{props.phone_1}</a>}
					{props.phone_2 && <a href={`tel:${props.phone_2}`}>{props.phone_2}</a>}
					{props.phone_3 && <a href={`tel:${props.phone_3}`}>{props.phone_3}</a>}
				</div>
				{props.street_view && (
					<Link to={{ pathname: props.street_view }} target="_blank" className="small-button">
						<LiveView color={"#393E4A"} />
					</Link>
				)}
				{props.itinerary && (
					<Link to={{ pathname: props.itinerary }} target="_blank" className="small-button">
						<Location color={"#393E4A"} />
					</Link>
				)}
				<Link to={`/customers/edit/${props.customer_id}`} className="small-button">
					<Edit color={"#393E4A"} />
				</Link>
			</div>
		</div>
	)
}

export default CustomerCard
