import React, { createContext, useReducer, useContext } from "react"
import { v4 } from "uuid"
import Alert from "../Components/Global/AlertsItem"

const AlertContext = createContext()

const AlertProvider = (props) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'ADD_ALERT':
				return [...state, {...action.payload}]
			case 'REMOVE_ALERT':
				return state.filter(el => el.id !== action.id)
			default:
				return state
		}
	}, [])
	return (
		<AlertContext.Provider value={dispatch}>
			<div className="alerts-wrapper">
				{state.map((el) => (
					<Alert key={el.id} dispatch={dispatch} {...el} />
				))}
			</div>
			{props.children}
		</AlertContext.Provider>
	)
}

// Custom hook to add new alert
export const useAlert = () => {
	const dispatch = useContext(AlertContext)

	return (props) => {
		dispatch({
			type: "ADD_ALERT",
			payload: {
				id: v4(),
				...props
			},
		})
	}
}


export default AlertProvider
