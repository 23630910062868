import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import "./Styles/reset.css"
import "./App.scss"
import Login from "./Components/Login/Login"
import Header from "./Components/Global/Header"
import ProductsList from "./Components/Products/ProductsList"
import ProductForm from "./Components/Products/ProductForm"
import ReportingDashboard from "./Components/Reporting/ReportingDashboard"
import ReportingForm from "./Components/Reporting/ReportingForm"
import InsightsDashboard from "./Components/Insights/InsightsDashboard"
import Customers from "./Components/Customers/CustomersDashboard"
import CustomerForm from "./Components/Customers/CustomerForm"
import Stocks from "./Components/Stocks/StocksDashboard"
import StockItemForm from "./Components/Stocks/StockItemForm"
import StockForm from "./Components/Stocks/StockForm"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import "./Styles/Media.scss"

const Home = () => {
	return <h1>Home component</h1>
}

const Orders = () => {
	return <h1>Orders component</h1>
}

const Settings = () => {
	return <h1>Paramètres component</h1>
}

const App = () => {
	// const isTablet = useMediaQuery({ query: "(max-width: 1194px)" })
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

	// User authentication
	const getToken = () => {
		const tokenString = localStorage.getItem("token")
		const userToken = JSON.parse(tokenString)
		return userToken
	}

	const setToken = (userToken) => {
		localStorage.setItem("token", JSON.stringify(userToken))
		setUser(userToken)
	}

	const deleteToken = (userToken) => {
		localStorage.removeItem("token")
		setUser(null)
	}

	const [user, setUser] = useState(getToken())

	const devEnv = process.env.NODE_ENV === "development" 
	if (!user && !devEnv) {
		return <Login setToken={setToken} />
	}

	return (
		<main className={`inner ${isMobile ? "mobile" : null}`}>
			<Router>
				<Header logOut={() => deleteToken()} isMobile={isMobile} />
				<div className="wrapper">
					<Routes>
						<Route path="/" exact element={<Home />} />
						<Route path="/products" exact element={<ProductsList />} />
						<Route path="/products/new" exact element={<ProductForm />} />
						<Route path="/products/edit/:id" exact element={<ProductForm />} />
						<Route path="/reporting" exact element={<ReportingDashboard />} />
						<Route path="/reporting/new" exact element={<ReportingForm />} />
						<Route path="/reporting/:id" exact element={<ReportingForm />} />
						<Route path="/reporting/insights" exact element={<InsightsDashboard />} />
						<Route path="/orders" exact element={<Orders />} />
						<Route path="/customers" exact element={<Customers />} />
						<Route path="/customers/new" exact element={<CustomerForm />} />
						<Route path="/customers/edit/:id" exact element={<CustomerForm />} />
						<Route path="/stocks" exact element={<Stocks />} />
						<Route path="/stocks/item/new" exact element={<StockItemForm />} />
						<Route path="/stocks/item/edit/:stockItemId" exact element={<StockItemForm />} />
						<Route path="/stocks/:id" exact element={<StockForm />} />
						<Route path="/settings" exact element={<Settings />} />
					</Routes>
				</div>
			</Router>
		</main>
	)
}

export default App
