import React, { useState, useEffect } from "react"
import axios from "axios"
import NumberFormat from "react-number-format"
import { useParams } from "react-router-dom"
import { useAlert } from "../../Context/Alert.Provider"
import Buttons from "../Global/Buttons"
import CustomerCity, { CustomerPayment } from "./CustomerStatics"

const CustomerForm = (props) => {
	// Get the custom hook from Alert.Provider.js
	const newAlert = useAlert()

	let { id } = useParams()

	const [newCustomer, setNewCustomer] = useState({})
	useEffect(() => {
		// If edition mode, fetch customer data with id
		if (id) {
			const url = `${process.env.REACT_APP_API_URL}Customers/GetCustomers.php`

			const api = axios.create()
			api.post(url, { id: id }).then((response) => {
				setNewCustomer(response.data[0])
			})
		}
	}, [id])

	// Get all current change from inputs
	const handleChange = (e) => {
		e.target.name 
			? setNewCustomer({ ...newCustomer, [e.target.name]: e.target.value })
			: setNewCustomer({ ...newCustomer, [e.target.dataset.name]: e.target.dataset.value })
	}
	// Set/Unset multiple checkbox
	const handleChangeMultiple = (e) => {
		const newEntry = newCustomer[e.target.dataset.name]
			? newCustomer[e.target.dataset.name].indexOf(e.target.dataset.value) !== -1
				? newCustomer[e.target.dataset.name].filter(el => el !== e.target.dataset.value)
				: [...newCustomer[e.target.dataset.name], e.target.dataset.value]
			: [e.target.dataset.value]
		setNewCustomer({
			...newCustomer,
			[e.target.dataset.name]: newEntry,
		})
	}

	// Send new customer data to controller
	const sendCustomer = (e) => {
		e.preventDefault()

		const api = axios.create()
		// If edit mode, use EditCustomer controller else use AddCustomer controller
		let url = id ? `${process.env.REACT_APP_API_URL}Customers/EditCustomer.php` : `${process.env.REACT_APP_API_URL}Customers/AddCustomer.php`
		api.post(url, newCustomer).then((response) => {
			newAlert({
				type: "success",
				message: `Le client <b>${newCustomer.address}</b> a bien été ${id ? "modifié" : "ajouté"}`
			})
			props.history.push(`/customers`)
		})
	}

	return (
		<section className="section size-3">
			<header className="header">
				<h2>{id ? `Éditer : ${newCustomer.address}` : "Nouveau client"}</h2>
				<div className="header-actions">
					<Buttons size="huge" type="full" color="main" icon="save" iconSize={20} iconColor="white" handleClick={(e) => sendCustomer(e)}>
						{id ? "Sauvegarder" : "Ajouter"}
					</Buttons>
				</div>
			</header>
			<form className="form product-form" onSubmit={(e) => sendCustomer(e)} autoComplete="off">
				<section className="col">
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Adresse</p>
							<label htmlFor="">
								<input
									type="text"
									className="field"
									name="address"
									value={newCustomer.address || undefined}
									onChange={(e) => handleChange(e)}
								/>
							</label>
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Ville</p>
							<ul className="pills">
								{CustomerCity &&
									CustomerCity.map((el) => (
										<li
											key={el}
											className={parseInt(newCustomer.city) === CustomerCity.indexOf(el) ? "active" : undefined}
											data-name="city"
											data-value={CustomerCity.indexOf(el)}
											onClick={(e) => handleChange(e)}>
											{el}
										</li>
									))}
							</ul>
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper field-price">
							<p className="field-label">Nom</p>
							<input
								type="text"
								className="field"
								name="customer_name"
								value={newCustomer.customer_name || undefined}
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Téléphone</p>
							<div className="customer-phones">
								<NumberFormat
									format="## ## ## ## ##"
									className="field"
									name="phone_1"
									value={newCustomer.phone_1 || undefined}
									onChange={(e) => handleChange(e)}
								/>
								<NumberFormat
									format="## ## ## ## ##"
									className="field"
									name="phone_2"
									value={newCustomer.phone_2 || undefined}
									onChange={(e) => handleChange(e)}
								/>
								<input
									type="text"
									className="field"
									name="phone_3"
									value={newCustomer.phone_3 || undefined}
									onChange={(e) => handleChange(e)}
								/>
							</div>
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Street View</p>
							<label htmlFor="">
								<input
									type="text"
									className="field"
									name="street_view"
									value={newCustomer.street_view || undefined}
									onChange={(e) => handleChange(e)}
								/>
							</label>
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Itinéraire</p>
							<label htmlFor="">
								<input
									type="text"
									className="field"
									name="itinerary"
									value={newCustomer.itinerary || undefined}
									onChange={(e) => handleChange(e)}
								/>
							</label>
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Paiement</p>
							<ul className="pills">
								{CustomerPayment.map((el) => (
									<li
										key={el.id}
										className={newCustomer.payment && newCustomer.payment.indexOf("" + el.id) !== -1 ? "active" : undefined}
										data-name="payment"
										data-value={el.id}
										onClick={(e) => handleChangeMultiple(e)}>
										{el.long}
									</li>
								))}
							</ul>
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Note</p>
							<textarea
								className="field textarea"
								name="note"
								rows="10"
								value={newCustomer.note || undefined}
								onChange={(e) => handleChange(e)}></textarea>
						</div>
					</article>
				</section>
			</form>
		</section>
	)
}

export default CustomerForm
