import React, { useState, useEffect } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import { useAlert } from "../../Context/Alert.Provider"
import Buttons from "../Global/Buttons"
import StockItemForm from "./StockItemForm"
import SearchBar from "../Global/SearchBar"

const StockForm = (props) => {
	// Get the custom hook from Alert.Provider.js
	const newAlert = useAlert()

	let { id } = useParams()

	const [stockItems, setStockItems] = useState([])
	const [stockTaking, setStockTaking] = useState({})
	const [searchItems, setSearchItems] = useState("")

	// Get stocktaking data from url id
	useEffect(() => {
		const url = `${process.env.REACT_APP_API_URL}Stocks/GetStockTakings.php`

		const api = axios.create()
		api.post(url, { id: id }).then((response) => {
			setStockTaking(response.data[0])
		})
	}, [id])

	// Get all stockitems to set stock
	useEffect(() => {
		const url = `${process.env.REACT_APP_API_URL}Stocks/GetStockItems.php`

		const api = axios.create()
		api.post(url).then((response) => {
			if (stockTaking.stockitem_list?.length) {
				const existsId = stockTaking.stockitem_list.map((d) => d.stockitem_id)
				const mergedStockItems = [
					...stockTaking.stockitem_list,
					...response.data.filter((d) => existsId.indexOf(d.stockitem_id) === -1),
				].sort((a, b) => a.stockitem_id - b.stockitem_id)
				setStockItems(mergedStockItems)
			} else {
				setStockItems(response.data)
			}
		})
	}, [stockTaking, stockTaking.stockitem_list])

	// Send stocktaking updated to controller
	const sendNewStock = (e) => {
		e.preventDefault()
		const stockItemsFiltered = stockItems.filter((item) => item.quantity > 0)

		const api = axios.create()
		// If edit mode, use EditStockItem controller else use AddStockItem controller
		let url = `${process.env.REACT_APP_API_URL}Stocks/SaveStockTaking.php`
		api.post(url, { ...stockTaking, stockitem_list: stockItemsFiltered }).then((response) => {
			newAlert({
				type: "success",
				message: `L'inventaire a bien été enregistré`,
			})
			// props.history.push(`/stocks`)
		})
	}

	const handleUpdate = (qt, item) => {
		// Update item quantity by his id
		setStockItems(stockItems.map((el) => (el.stockitem_id === item.stockitem_id ? { ...item, quantity: qt } : el)))
	}

	return (
		<section className="section size-5 stock-taking-form">
			<header className="header">
				<h2>{`Inventaire : ${stockTaking.name}`}</h2>
				<div className="header-actions">
					<Buttons size="huge" type="full" color="main" icon="save" iconSize={20} iconColor="white" handleClick={(e) => sendNewStock(e)}>
						{id ? "Sauvegarder" : "Ajouter"}
					</Buttons>
				</div>
			</header>
			<SearchBar
				onChange={(e) => setSearchItems(e.target.value)}
				clearSearch={() => setSearchItems("")}
				Placeholder="Rechercher"
				Value={searchItems}
			/>
			{/* stockItems List */}
			{stockItems
				.filter((item) => item.name.toLocaleLowerCase().indexOf(searchItems) !== -1 || item.barcode.indexOf(searchItems) !== -1)
				.map((el) => (
					<div key={el.stockitem_id} className="stock-form">
						<input
							type="number"
							className="field"
							min="0"
							value={el.quantity || ""}
							onChange={(e) => handleUpdate(e.target.value, el)}
							placeholder="0"
						/>
						<p>
							{el.name} <span className="mono">{el.barcode}</span>
						</p>
					</div>
				))}
			{/* Add new stockItem to database and stocktaking list */}
			<StockItemForm getNewStockItem={(e) => setStockItems([...stockItems, e])} isInclude />
		</section>
	)
}

export default StockForm
