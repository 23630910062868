const ReportingField = (props) => {
    return (
		<>
			<label htmlFor={props.labelFor} className="field-label">
				{props.label}
			</label>
			<div className="amount-field">
				<input
					type="number"
					step="0.01"
					className="field"
					id={props.labelFor}
					name={props.labelFor}
					onChange={props.onChange}
					defaultValue={props.value}
				/>
			</div>
		</>
	)
}

export default ReportingField