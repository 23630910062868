import React from "react"
import "../../Styles/Header.scss"
import { Link, useMatch } from "react-router-dom"
import uuid from "react-uuid"
import Lotus from "../../Assets/asian-street-food-lotus.svg"
import IconDashboard from "../../Assets/Icons/dashboard.svg"
import IconProducts from "../../Assets/Icons/products.svg"
import IconReporting from "../../Assets/Icons/reporting.svg"
// import IconOrders from "../../Assets/Icons/orders.svg"
import IconCustomers from "../../Assets/Icons/customers.svg"
import IconStock from "../../Assets/Icons/stock.svg"
import IconSetting from "../../Assets/Icons/settings.svg"

const Tab = ({ children, to, isExact, handleClick }) => {
	let match = useMatch({
		path: to,
		exact: isExact,
	})

	return (
		<Link to={to} onClick={handleClick}>
			<li className={match && "active"}>{children}</li>
		</Link>
	)
}

const Nav = ({ logOut, isMobile }) => {

	const tabs = [
		{ label: "Dashboard", icon: `${IconDashboard}`, path: "/", isExact: true },
		{ label: "Produits", icon: `${IconProducts}`, path: "/products", isExact: false },
		{ label: "Bilan", icon: `${IconReporting}`, path: "/reporting", isExact: false },
		// { label: "Commandes", icon: `${IconOrders}`, path: "/orders", isExact: false },
		{ label: "Clients", icon: `${IconCustomers}`, path: "/customers", isExact: false },
		{ label: "Inventaires", icon: `${IconStock}`, path: "/stocks", isExact: false },
		{ label: "Paramètres", icon: `${IconSetting}`, path: "/settings", isExact: false },
	]

	return (
		<aside className={`main-aside`}>
			<header>
				{!isMobile && (
					<Link to="/" exact="true">
						<img src={Lotus} alt="" />
					</Link>
				)}
			</header>
			{!isMobile ? (
				<nav>
					<ul>
						{tabs.map((tab) => (
							<Tab key={uuid()} to={tab.path} isExact={tab.isExact}>
								<img src={tab.icon} alt="" />
								<p>{tab.label}</p>
							</Tab>
						))}
					</ul>
					<li onClick={() => logOut()}>
						<p>Déconnexion</p>
					</li>
				</nav>
			) : (
				<nav>
					<ul>
						{tabs.map((tab) => (
							<Tab key={uuid()} to={tab.path} isExact={tab.isExact}>
								<img src={tab.icon} alt="" />
								<p>{tab.label}</p>
							</Tab>
						))}
					</ul>
				</nav>
			)}
		</aside>
	)
}

export default Nav
