import React from "react"
import Moment from "react-moment"
import moment from "moment"
import { ResponsiveContainer, ComposedChart, Bar, XAxis, Tooltip } from "recharts"
import { useMediaQuery } from "react-responsive"
import NumberFormat from "react-number-format"
import { Link } from "react-router-dom"

const ReportingByMonth = ({ data }) => {

    const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
    function MonthlyTooltip({ payload, label, active }) {
		return active ? (
			<div className="rechart-tooltip">
				<p className="title">{moment(payload[0].payload.date).format("MMMM YYYY")}</p>
				<p className="detail">CA : {payload[0].value.toFixed(2)}€</p>
			</div>
		) : null
	}

	return (
		<>
			{[...data].reverse().map((year, key) => (
				<div className="revenue-month" key={key}>
					<h3>
						<Moment format="YYYY" date={`${year.year}`} />
					</h3>
					{/* Annual global data charts */}
					<div className="revenue-month-global">
						<div className="revenue-month-global-graph">
							<ResponsiveContainer width="100%" height={150}>
								<ComposedChart data={year.monthlyIncome} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
									<Tooltip content={<MonthlyTooltip />} />
									<XAxis dataKey="date" stroke="#8c8c8c" tickFormatter={(tick) => moment(tick).format("MMM")} />
									<Bar dataKey="income" fill="#a75fa6" />
								</ComposedChart>
							</ResponsiveContainer>
						</div>
					</div>
					<div className="table revenue-tab all">
						<div className="tab-row tab-header">
							<div className="tab-col">Mois</div>
							<div className="tab-col">Graph</div>
							<div className="tab-col align-right">CA</div>
							<div className="tab-col align-right">CA/j</div>
							<div className="tab-col align-right">Bénéf</div>
							<div className="tab-col align-right">CB</div>
							<div className="tab-col align-right">ESP</div>
							<div className="tab-col align-right">TR</div>
							<div className="tab-col align-right">Action</div>
						</div>
						{/* Year total */}
						<div className="tab-row tab-sumup">
							<p className="col tab-body mono">Total</p>
							<div>&nbsp;</div>
							<p className="col tab-body mono bold align-right">
								{isMobile && <span className="mobile-title">CA</span>}
								<NumberFormat
									value={year.income.total || 0}
									decimalScale={0}
									displayType={"text"}
									decimalSeparator={","}
									suffix={"€"}
									thousandSeparator={" "}
								/>
								<i>
									<NumberFormat
										value={year.z_checkout.total || 0}
										decimalScale={0}
										displayType={"text"}
										decimalSeparator={","}
										suffix={"€"}
										thousandSeparator={" "}
									/>
									(
									<NumberFormat
										value={year.z_checkout.rate || 0}
										decimalScale={0}
										displayType={"text"}
										decimalSeparator={","}
										suffix={"%"}
									/>
									)
								</i>
							</p>
							<p className="col tab-body mono align-right">
								{isMobile && <span className="mobile-title">CA/J</span>}
								-
								<NumberFormat
									value={year.openDays || 0}
									decimalScale={0}
									displayType={"text"}
									renderText={(value) => <i>{value}/365</i>}
								/>
							</p>
							<p className="col tab-body mono align-right">
								{isMobile && <span className="mobile-title">Bénéf</span>}
								<NumberFormat
									value={year.profit.total || 0}
									decimalScale={0}
									displayType={"text"}
									suffix={"€"}
									decimalSeparator={","}
									thousandSeparator={" "}
								/>
							</p>
							<p className="col tab-body mono align-right">
								{isMobile && <span className="mobile-title">CB</span>}
								<NumberFormat
									value={year.cb.total || 0}
									decimalScale={0}
									displayType={"text"}
									suffix={"€"}
									decimalSeparator={","}
									thousandSeparator={" "}
								/>
								<NumberFormat
									value={year.cb.rate || 0}
									decimalScale={0}
									displayType={"text"}
									renderText={(value) => <i>{value}%</i>}
									decimalSeparator={","}
								/>
							</p>
							<p className="col tab-body mono align-right">
								{isMobile && <span className="mobile-title">ESP</span>}
								<NumberFormat
									value={year.esp.total || 0}
									decimalScale={0}
									displayType={"text"}
									suffix={"€"}
									decimalSeparator={","}
									thousandSeparator={" "}
								/>
								<NumberFormat
									value={year.esp.rate || 0}
									decimalScale={0}
									displayType={"text"}
									renderText={(value) => <i>{value}%</i>}
									decimalSeparator={","}
								/>
							</p>
							<p className="col tab-body mono align-right">
								{isMobile && <span className="mobile-title">TR</span>}
								<NumberFormat
									value={year.tr.total || 0}
									decimalScale={0}
									displayType={"text"}
									suffix={"€"}
									decimalSeparator={","}
									thousandSeparator={" "}
								/>
								<NumberFormat
									value={year.tr.rate || 0}
									decimalScale={0}
									displayType={"text"}
									renderText={(value) => <i>{value}%</i>}
									decimalSeparator={","}
								/>
							</p>
							<div className="col tab-body action align-right"></div>
						</div>
						{/* Month average */}
						<div className="tab-row tab-sumup">
							<p className="col tab-body mono">Moyenne</p>
							<div>&nbsp;</div>
							<p className="col tab-body mono bold align-right">
								{isMobile && <span className="mobile-title">CA</span>}
								<NumberFormat
									value={year.income.totalAvgMonthly || 0}
									decimalScale={0}
									displayType={"text"}
									decimalSeparator={","}
									suffix={"€"}
									thousandSeparator={" "}
								/>
								<NumberFormat
									value={year.z_checkout.totalAvgMonthly || 0}
									decimalScale={0}
									displayType={"text"}
									renderText={(value) => <i>{value}€</i>}
									decimalSeparator={","}
									thousandSeparator={" "}
								/>
							</p>
							<p className="col tab-body mono align-right">
								{isMobile && <span className="mobile-title">CA/J</span>}
								<NumberFormat
									value={year.income.totalAvgDaily || 0}
									decimalScale={2}
									displayType={"text"}
									decimalSeparator={","}
									suffix={"€"}
									thousandSeparator={" "}
								/>
								<NumberFormat
									value={year.openDays / year.openMonths || 0}
									decimalScale={0}
									displayType={"text"}
									renderText={(value) => <i>{value} j / mois</i>}
									decimalSeparator={","}
									thousandSeparator={" "}
								/>
							</p>
							<p className="col tab-body mono align-right">
								{isMobile && <span className="mobile-title">Bénéf</span>}
								<NumberFormat
									value={year.profit.totalAvgMonthly || 0}
									decimalScale={0}
									displayType={"text"}
									decimalSeparator={","}
									suffix={"€"}
									thousandSeparator={" "}
								/>
								<NumberFormat
									value={year.profit.totalAvgDaily || 0}
									decimalScale={0}
									displayType={"text"}
									renderText={(value) => <i>{value}€</i>}
									decimalSeparator={","}
									thousandSeparator={" "}
								/>
							</p>
							<p className="col tab-body mono align-right">
								{isMobile && <span className="mobile-title">CB</span>}
								<NumberFormat
									value={year.cb.totalAvgMonthly || 0}
									decimalScale={0}
									displayType={"text"}
									decimalSeparator={","}
									suffix={"€"}
									thousandSeparator={" "}
								/>
							</p>
							<p className="col tab-body mono align-right">
								{isMobile && <span className="mobile-title">ESP</span>}
								<NumberFormat
									value={year.esp.totalAvgMonthly || 0}
									decimalScale={0}
									displayType={"text"}
									decimalSeparator={","}
									suffix={"€"}
									thousandSeparator={" "}
								/>
							</p>
							<p className="col tab-body mono align-right">
								{isMobile && <span className="mobile-title">TR</span>}
								<NumberFormat
									value={year.tr.totalAvgMonthly || 0}
									decimalScale={0}
									displayType={"text"}
									decimalSeparator={","}
									suffix={"€"}
									thousandSeparator={" "}
								/>
							</p>
							<div className="col tab-body action align-right"></div>
						</div>
						{/* Day by day */}
						{[...year.byMonth].reverse().map((month, key) => (
							<div className="tab-row" key={key}>
								<p className="col tab-body mono">
									<Moment format="MMMM YYYY" date={month.date} />
								</p>
								<div>
									<ResponsiveContainer width="100%" height={20}>
										<ComposedChart data={month.dailyIncome} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
											{/* <Tooltip content={<MonthlyTooltip />} /> */}
											<Bar dataKey="revenue" fill="#a75fa6" />
										</ComposedChart>
									</ResponsiveContainer>
								</div>
								<p className="col tab-body mono bold align-right">
									{isMobile && <span className="mobile-title">CA</span>}
									<NumberFormat
										value={month.income.total || 0}
										decimalScale={0}
										displayType={"text"}
										decimalSeparator={","}
										suffix={"€"}
										thousandSeparator={" "}
									/>
									<i>
										<NumberFormat
											value={month.z_checkout.total || 0}
											decimalScale={0}
											displayType={"text"}
											decimalSeparator={","}
											suffix={"€"}
											thousandSeparator={" "}
										/>
										<NumberFormat
											value={month.z_checkout.rate || 0}
											decimalScale={0}
											displayType={"text"}
											renderText={(value) => `(${value}%)`}
											decimalSeparator={","}
											thousandSeparator={" "}
										/>
									</i>
								</p>
								<p className="col tab-body mono bold align-right">
									{isMobile && <span className="mobile-title">CA</span>}
									<NumberFormat
										value={month.income.totalAvgDaily || 0}
										decimalScale={0}
										displayType={"text"}
										decimalSeparator={","}
										suffix={"€"}
										thousandSeparator={" "}
									/>
									<NumberFormat
										value={month.openDays || 0}
										displayType={"text"}
										renderText={(value) => (
											<i>
												{value}/{moment(month.date, "YYYY-MM").daysInMonth()}
											</i>
										)}
									/>
								</p>
								<p className="col tab-body mono align-right">
									{isMobile && <span className="mobile-title">Bénéf</span>}
									<NumberFormat
										value={month.profit.total || 0}
										decimalScale={2}
										displayType={"text"}
										decimalSeparator={","}
										suffix={"€"}
										thousandSeparator={" "}
									/>
									<NumberFormat
										value={month.profit.totalAvgDaily || 0}
										decimalScale={2}
										displayType={"text"}
										renderText={(value) => <i>{value}€</i>}
										decimalSeparator={","}
									/>
								</p>
								{/* CB */}
								<p className="col tab-body mono align-right">
									{isMobile && <span className="mobile-title">CB</span>}
									<NumberFormat
										value={month.cb.total || 0}
										decimalScale={0}
										displayType={"text"}
										decimalSeparator={","}
										suffix={"€"}
										thousandSeparator={" "}
									/>
									<NumberFormat
										value={month.cb.totalAvg || 0}
										decimalScale={2}
										displayType={"text"}
										renderText={(value) => <i>{value}€</i>}
										decimalSeparator={","}
										thousandSeparator={" "}
									/>
								</p>
								{/* ESP */}
								<p className="col tab-body mono align-right">
									{isMobile && <span className="mobile-title">ESP</span>}
									<NumberFormat
										value={month.esp.total || 0}
										decimalScale={0}
										displayType={"text"}
										decimalSeparator={","}
										suffix={"€"}
										thousandSeparator={" "}
									/>
									<NumberFormat
										value={month.esp.totalAvg || 0}
										decimalScale={2}
										displayType={"text"}
										renderText={(value) => <i>{value}€</i>}
										decimalSeparator={","}
										thousandSeparator={" "}
									/>
								</p>
								{/* TR */}
								<p className="col tab-body mono align-right">
									{isMobile && <span className="mobile-title">TR</span>}
									<NumberFormat
										value={month.tr.total || 0}
										decimalScale={0}
										displayType={"text"}
										decimalSeparator={","}
										suffix={"€"}
										thousandSeparator={" "}
									/>
									<NumberFormat
										value={month.tr.totalAvg || 0}
										decimalScale={2}
										displayType={"text"}
										renderText={(value) => <i>{value}€</i>}
										decimalSeparator={","}
										thousandSeparator={" "}
									/>
								</p>
								<div className="col tab-body action align-right">
									{/* <Link to={`/reporting/${month.id}`}>Détail</Link> */}
									&nbsp;
								</div>
							</div>
						))}
					</div>
				</div>
			))}
		</>
	)
}

export default ReportingByMonth