import React, { useState, useEffect } from "react"
import "./Reporting.scss"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import Moment from "react-moment"
import moment from "moment"
import DayPickerInput from "react-day-picker/DayPickerInput"
import MomentLocaleUtils, { formatDate, parseDate } from "react-day-picker/moment"
import "react-day-picker/lib/style.css"
import "moment/locale/fr"
import { useParams } from "react-router-dom"
import { useAlert } from "../../Context/Alert.Provider"
import Buttons from "../Global/Buttons"
import ReportingField from "./ReportingField"
import ReportingFieldNumber from "./ReportingFieldNumber"

const ReportingForm = (props) => {
	let navigate = useNavigate()

	const newAlert = useAlert()
	let { id } = useParams()
	// const [updatedId, setUpdatedId] = useState(id || null)
	const mealCardCompany = ["Bimpli", "Edenred", "Up Déjeuner", "Sodexo"]
	const expenseTemplate = {
		id: 1,
		title: "",
		amount: "",
	}
	const defaultSubtotals = {
		subtotal_cash: "",
		cash_left: "",
		subtotal_contact: "",
		subtotal_card: "",
		subtotal_meal_ticket: "",
		subtotal_meal_card: "",
		subtotal_expenses: "",
		revenue: "",
	}
	const defaultValues = {
		coin: "",
		bill: "",
		delivery_money: "",
		stock_coin: "",
		cash_out: "",
		advance: "",
		contact_less: "",
		contact_less_number: "",
		contact_pickup: "",
		contact_pickup_number: "",
		contact: "",
		contact_number: "",
		nas_contact_less: "",
		nas_contact_less_number: "",
		nas_contact: "",
		nas_contact_number: "",
		meal_ticket: "",
		meal_ticket_number: "",
		meal_ticket_out: "",
		meal_ticket_out_number: "",
		meal_card: [
			{ id: 0, number: "", amount: "" }, // Bimpli
			{ id: 1, number: "", amount: "" }, // Edenred
			{ id: 2, number: "", amount: "" }, // UpDéjeuner
			{ id: 3, number: "", amount: "" }, // Sodexo
		],
		cheque: "",
		cheque_number: "",
	}
	const [values, setValues] = useState(defaultValues)
	const [expenses, setExpenses] = useState([expenseTemplate])
	const [subTotals, setSubTotals] = useState(defaultSubtotals)
	const [selectedDay, setSelectedDay] = useState(new Date())
	const [disabledDay, setDisabledDay] = useState([])
	const [hiddenInput, setHiddenInput] = useState({ cheque: false, cb_nas: false })
	// Set default datas
	useEffect(() => {
		// Edit/View mode
		if (id) {
			const url = `${process.env.REACT_APP_API_URL}Reporting/GetRevenue.php`
			const api = axios.create()

			api.post(url, { id: id }).then((response) => {
				response.data.meal_card = JSON.parse(response.data.meal_card) || defaultValues.meal_card
				setValues(response.data)
				response.data.expenses != null ? setExpenses(JSON.parse(response.data.expenses)) : setExpenses([expenseTemplate])

				const [year, month, day] = [...response.data.day.split("-")]
				setSelectedDay(new Date(year, month - 1, day))
				newAlert({
					type: "success",
					message: `Chargement réussi`,
				})
			})
		} else {
			// Create mode
			const url = `${process.env.REACT_APP_API_URL}Reporting/GetStatics.php`
			const api = axios.create()

			// Get localstorage saved values OR last revenue values
			localStorage.getItem("unsavedReporting")
				? setValues(JSON.parse(localStorage.getItem("unsavedReporting")))
				: api.post(url).then((response) => {
						setDisabledDay(response.data.disabledDays.map((d) => new Date([...d.split("-")])))
						setValues({ ...values, ...response.data.lastRevenue })
				  })
		}
		// eslint-disable-next-line
	}, [])

	// Send new revenue data to controller
	const sendProduct = (e) => {
		e.preventDefault()
		const api = axios.create()
		const sendValues = {
			...values,
			day: selectedDay.getTime(),
			meal_card: JSON.stringify(values.meal_card),
			expenses: JSON.stringify(expenses),
			...subTotals,
		}

		// If edit mode, use EditProduct controller else use AddProduct controller
		if (id) {
			let url = `${process.env.REACT_APP_API_URL}Reporting/EditRevenue.php`
			api.post(url, { ...sendValues, id: id }).then((response) => {
				newAlert({
					type: "success",
					message: `Modification réussie : ${formatDate(selectedDay, "L", "fr")}`,
				})
				navigate(`/reporting`)
			})
		} else {
			let url = `${process.env.REACT_APP_API_URL}Reporting/AddRevenue.php`
			api.post(url, sendValues).then((response) => {
				newAlert({
					type: "success",
					message: `Le bilan du jour a bien été ajouté`,
				})
				navigate(`/reporting`)
			})
		}
		localStorage.removeItem("unsavedReporting")
	}

	const handleChange = (e) => {
		const amount = parseFloat(e.value.replace(/,/, ".")) || null
		const updatedValues = { ...values, [e.name]: amount }

		// Store in localStorage unsaved values
		!id && localStorage.setItem("unsavedReporting", JSON.stringify(updatedValues))
		setValues(updatedValues)
	}

	const handleMealCard = (e, updateID) => {
		const newMealCardValue = [
			...values.meal_card.filter((i) => i.id !== updateID),
			{ ...values.meal_card[updateID], [e.target.name]: parseFloat(e.target.value.replace(/,/, ".")) || null },
		].sort((a, b) => a.id - b.id)

		const updatedValues = { ...values, meal_card: newMealCardValue }

		// Store in localStorage unsaved values
		!id && localStorage.setItem("unsavedReporting", JSON.stringify(updatedValues))
		setValues(updatedValues)
	}

	// Calculate readonly amount
	useEffect(() => {
		const total_cash =
			parseFloat(values.coin || 0) + parseFloat(values.bill || 0) + parseFloat(values.delivery_money || 0) + parseFloat(values.stock_coin || 0)
		const cash_left = total_cash - (values.advance || 0)
		const subtotal_contact = parseFloat(values.contact_pickup || 0) + parseFloat(values.contact || 0)
		const subtotal_card =
			parseFloat(values.contact_less || 0) +
			parseFloat(values.contact_pickup || 0) +
			parseFloat(values.contact || 0) +
			parseFloat(values.nas_contact_less || 0) +
			parseFloat(values.nas_contact || 0)
		const subtotal_meal_ticket = parseFloat(values.meal_ticket || 0) + parseFloat(values.meal_ticket_out || 0)
		const subtotal_meal_card = values.meal_card?.reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0)
		const subtotal_expenses = expenses?.reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0)
		const revenue =
			parseFloat(values.cash_out || 0) +
			cash_left +
			subtotal_card +
			subtotal_meal_ticket +
			subtotal_meal_card +
			parseFloat(values.cheque || 0) +
			subtotal_expenses
		setSubTotals({
			total_cash: parseFloat(total_cash),
			cash_left: parseFloat(cash_left),
			subtotal_contact: parseFloat(subtotal_contact),
			subtotal_card: parseFloat(subtotal_card),
			subtotal_meal_ticket: parseFloat(subtotal_meal_ticket),
			subtotal_meal_card: parseFloat(subtotal_meal_card),
			subtotal_expenses: subtotal_expenses,
			revenue: parseFloat(revenue),
		})
	}, [values, expenses, values.meal_card])

	// Expenses functions
	// Add new expense
	const handleNewExpense = () => {
		const maxId = Math.max(...expenses.map((exp) => exp.id))
		const newExpense = {
			id: maxId + 1,
			title: null,
			amount: null,
		}
		setExpenses([...expenses, newExpense])
	}
	const handleUpdateExpense = (e, item) => {
		setExpenses(
			expenses.map((elem) => {
				if (elem.id === parseInt(e.dataset.id)) {
					return { ...elem, [e.name]: e.value }
				}
				return elem
			})
		)
	}
	return (
		<section className="section size-6">
			<header className="header sticky reporting-form-header">
				<h2>
					<p>Chiffre d'affaires du</p>
					<DayPickerInput
						onDayChange={setSelectedDay}
						formatDate={formatDate}
						parseDate={parseDate}
						placeholder={formatDate(selectedDay, "L", "fr")}
						value={selectedDay}
						dayPickerProps={{
							locale: "fr",
							localeUtils: MomentLocaleUtils,
							disabledDays: disabledDay,
						}}
					/>
				</h2>
				<div className="header-actions">
					<Buttons size="huge" type="full" color="main" icon="save" iconSize={20} iconColor="white" handleClick={(e) => sendProduct(e)}>
						{id ? "Modifier" : "Enregistrer"}
					</Buttons>
				</div>
			</header>
			<form className="form reporting-form" onSubmit={(e) => e.preventDefault()}>
				<section className="entry">
					<article>
						<h3 className="subtitle">Caisse</h3>
						<div className="grid level-1">
							<ReportingField
								labelFor={"delivery_money"}
								label={"Trousse*"}
								value={values.delivery_money}
								onChange={(e) => handleChange(e.target)}
							/>
							<ReportingField
								labelFor={"stock_coin"}
								label={"Stock monnaie"}
								value={values.stock_coin}
								onChange={(e) => handleChange(e.target)}
							/>
							<ReportingField
								labelFor={"coin"}
								label={"Monnaie caisse*"}
								value={values.coin}
								onChange={(e) => handleChange(e.target)}
							/>
							<ReportingField labelFor={"bill"} label={"Billet"} value={values.bill} onChange={(e) => handleChange(e.target)} />
							<ReportingField
								labelFor={"cash_out"}
								label={"Espèce off"}
								value={values.cash_out}
								onChange={(e) => handleChange(e.target)}
							/>
						</div>
						<div className="grid level-2">
							<div className="bracket height-6"></div>
						</div>
						<div className="grid level-3">
							<aside className="subtotal-size-5">
								<div className="subtotal">
									<p className="amount amount-field field">{subTotals.total_cash?.toFixed(2)}</p>
									<span className="label">
										Acc. du <Moment date={moment(selectedDay).add(1, "d")} format="DD/MM" />
									</span>
								</div>
								<div className="subtotal">
									<div className="amount-field">
										<input
											type="number"
											step="0.01"
											className="field"
											id="advance"
											name="advance"
											onChange={(e) => handleChange(e.target)}
											defaultValue={values.advance}
										/>
									</div>
									<span className="label">
										Acc. du <Moment date={moment(selectedDay).subtract(1, "d")} format="DD/MM" />*
									</span>
								</div>
								<div className="subtotal">
									<p className="amount amount-field field">{subTotals.cash_left && subTotals.cash_left.toFixed(2)}</p>
									<span className="label">Total ESP</span>
								</div>
							</aside>
						</div>
					</article>
					<article>
						<h3 className="subtitle">Carte bleue</h3>
						<div className="grid level-1">
							<ReportingFieldNumber
								label={"Sans contact"}
								labelFor={"contact_less"}
								labelForNumber={"contact_less_number"}
								valueNumber={values.contact_less_number}
								value={values.contact_less}
								onChange={(e) => handleChange(e.target)}
							/>
							<ReportingFieldNumber
								label={"Contact collecté"}
								labelFor={"contact_pickup"}
								labelForNumber={"contact_pickup_number"}
								valueNumber={values.contact_pickup_number}
								value={values.contact_pickup}
								onChange={(e) => handleChange(e.target)}
							/>
							<ReportingFieldNumber
								label={"Contact"}
								labelFor={"contact"}
								labelForNumber={"contact_number"}
								valueNumber={values.contact_number}
								value={values.contact}
								onChange={(e) => handleChange(e.target)}
							/>
							{values.nas_contact_less || values.nas_contact || hiddenInput.cb_nas ? (
								<>
									<ReportingFieldNumber
										label={"NAS Sans contact"}
										labelFor={"nas_contact_less"}
										labelForNumber={"nas_contact_less_number"}
										valueNumber={values.nas_contact_less_number}
										value={values.nas_contact_less}
										onChange={(e) => handleChange(e.target)}
									/>
									<ReportingFieldNumber
										label={"NAS Contact"}
										labelFor={"nas_contact"}
										labelForNumber={"nas_contact_number"}
										valueNumber={values.nas_contact_number}
										value={values.nas_contact}
										onChange={(e) => handleChange(e.target)}
									/>
								</>
							) : (
								<Buttons
									handleClick={() =>
										setHiddenInput((prev) => {
											return { ...prev, cb_nas: true }
										})
									}
									size="small"
									type="full"
									color="grey">
									Ajouter les CB NAS
								</Buttons>
							)}
						</div>
						<div className="grid level-2">
							<div className="bracket height-5"></div>
							{/* <div className="bracket height-2 subbracket"></div> */}
						</div>
						<div className="grid level-3">
							<aside className="subtotal-size-5">
								{/* <div className="subtotal subtotal-contactless">
									<p className="amount amount-field field">{parseFloat(subTotals.subtotal_contact).toFixed(2)}</p>
									<span className="label">SS Total Contact</span>
								</div> */}
								<div className="subtotal subtotal-credit-card">
									<p className="amount amount-field field">{parseFloat(subTotals.subtotal_card).toFixed(2)}</p>
									<span className="label">Total CB</span>
								</div>
							</aside>
						</div>
					</article>
					<article>
						<h3 className="subtitle">Ticket restaurant</h3>
						<div className="grid level-1">
							<ReportingFieldNumber
								label={"TR de"}
								labelFor={"meal_ticket"}
								labelForNumber={"meal_ticket_number"}
								valueNumber={values.meal_ticket_number}
								value={values.meal_ticket}
								onChange={(e) => handleChange(e.target)}
							/>
							<ReportingFieldNumber
								label={"TR off de"}
								labelFor={"meal_ticket_out"}
								labelForNumber={"meal_ticket_out_number"}
								valueNumber={values.meal_ticket_out_number}
								value={values.meal_ticket_out}
								onChange={(e) => handleChange(e.target)}
							/>
						</div>
						<div className="grid level-2">
							<div className="bracket"></div>
						</div>
						<div className="grid level-3">
							<aside className="subtotal-size-2">
								<div className="subtotal">
									<p className="amount amount-field field">{parseFloat(subTotals.subtotal_meal_ticket).toFixed(2)}</p>
									<span className="label">Total TR</span>
								</div>
							</aside>
						</div>
					</article>
					<article>
						<h3 className="subtitle">Carte ticket restaurant</h3>
						<div className="grid level-1">
							{values.meal_card.map((company) => (
								<ReportingFieldNumber
									key={company.id}
									label={mealCardCompany[company.id]}
									labelFor={"amount"}
									labelForNumber={"number"}
									valueNumber={values.meal_card[company.id].number}
									value={values.meal_card[company.id].amount}
									onChange={(e) => handleMealCard(e, company.id)}
								/>
							))}
						</div>
						<div className="grid level-2">
							<div className="bracket"></div>
						</div>
						<div className="grid level-3">
							<aside className="subtotal-size-2">
								<div className="subtotal">
									<p className="amount amount-field field">{parseFloat(subTotals.subtotal_meal_card).toFixed(2)}</p>
									<span className="label">Total TR</span>
								</div>
							</aside>
						</div>
					</article>
					<article>
						<h3 className="subtitle">Dépense</h3>
						<div>
							{expenses &&
								expenses.map((item, key) => (
									<div className="grid level-1 expense" key={key}>
										<input
											type="text"
											className="field"
											name="title"
											data-id={item.id}
											defaultValue={item.title}
											onChange={(e) => handleUpdateExpense(e.target, item)}
										/>
										<div className="amount-field">
											<input
												type="number"
												step="0.01"
												className="field"
												name="amount"
												data-id={item.id}
												defaultValue={item.amount}
												onChange={(e) => handleUpdateExpense(e.target, item)}
											/>
										</div>
									</div>
								))}
						</div>
						<div className="grid level-2">
							<div className="bracket"></div>
						</div>
						<div className="grid level-3">
							<aside>
								<div className="subtotal">
									<p className="amount amount-field field">{parseFloat(subTotals.subtotal_expenses).toFixed(2)}</p>
									<span className="label">Total Dépense</span>
								</div>
							</aside>
						</div>
					</article>
					{values.cheque || hiddenInput.cheque ? (
						<article>
							<h3 className="subtitle">Chèque</h3>
							<div className="grid level-1">
								<ReportingFieldNumber
									label={"Chèque"}
									labelFor={"cheque"}
									labelForNumber={"cheque_number"}
									valueNumber={values.cheque_number}
									value={values.cheque}
									onChange={(e) => handleChange(e.target)}
								/>
							</div>
							<div className="grid level-2">
								<div className="bracket single"></div>
							</div>
							<div className="grid level-3">
								<aside>
									<div className="subtotal single">
										<p className="amount amount-field field">{values.cheque}</p>
										<span className="label">Total CHQ</span>
									</div>
								</aside>
							</div>
						</article>
					) : (
						<Buttons
							handleClick={() =>
								setHiddenInput((prev) => {
									return { ...prev, cheque: true }
								})
							}
							size="small"
							type="full"
							color="grey">
							Ajouter les chèques
						</Buttons>
					)}
				</section>
				<section className="total-section">
					<div className="bracket"></div>
					<div className="total-amount-wrapper">
						<div className="total-bloc total-amount">
							<p className="label">CA Total</p>
							<p className="amount-field field">{subTotals.revenue && subTotals.revenue.toFixed(2)}€</p>
						</div>
						<div className="total-bloc total">
							<p className="label">CA Brut</p>
							<div className="amount-field">
								<input
									type="number"
									step="0.01"
									className="field"
									name="z_checkout"
									onChange={(e) => handleChange(e.target)}
									defaultValue={values.z_checkout}
								/>
							</div>
						</div>
						<div className="total-bloc total">
							<p className="label">ESP à déclarer</p>
							<p className="amount amount-field field">{parseInt((subTotals.revenue - values.z_checkout) / 10) || 0}</p>
						</div>
					</div>
				</section>
			</form>

			<Buttons
				customClass={"btn-add-expense"}
				handleClick={() => handleNewExpense()}
				size="huge"
				type="full"
				color="main"
				icon="plus"
				iconSize="20"
				iconColor="white">
				Nouvelle dépense
			</Buttons>
		</section>
	)
}

export default ReportingForm
