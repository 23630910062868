import React from "react"
import { Link } from "react-router-dom"
import CustomerCity, { CustomerPayment } from "./CustomerStatics"
import { Edit, Location, LiveView } from "tabler-icons-react"

const CustomerItem = (props) => {
	const payments = props.payment?.map((el) => CustomerPayment.find((e) => parseInt(el) === e.id))
	return (
		<div className="tab-row">
			<p className="col tab-body">
				{props.address} {props.customer_name && " - " + props.customer_name}
			</p>
			<p className="col tab-body">{CustomerCity[props.city]}</p>
			<p className="col tab-body customer-phones">
				{props.phone_1 && (
					<span>
						<a href={`tel:${props.phone_1}`}>{props.phone_1}</a>
					</span>
				)}
				{props.phone_2 && (
					<span>
						<a href={`tel:${props.phone_2}`}>{props.phone_2}</a>
					</span>
				)}
				{props.phone_3 && (
					<span>
						<a href={`tel:${props.phone_3}`}>{props.phone_3}</a>
					</span>
				)}
			</p>
			<p className="col tab-body tags-inner">
				{payments
					? payments.map((pay) => (
							<span className="tags small" key={pay.id}>
								{pay.short}
							</span>
					  ))
					: "-"}
			</p>
			<div className="col tab-body action">
				{props.street_view && (
					<Link to={{ pathname: props.street_view }} target="_blank">
						<LiveView color={"#DEDEDE"} />
					</Link>
				)}
				{props.itinerary && (
					<Link to={{ pathname: props.itinerary }} target="_blank">
						<Location color={"#DEDEDE"} />
					</Link>
				)}
				<Link to={`/customers/edit/${props.customer_id}`}>
					<Edit color={"#DEDEDE"} />
				</Link>
			</div>
		</div>
	)
}

export default CustomerItem
