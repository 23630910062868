import React, { useEffect, useState } from "react"
import "./Insights.scss"
import { useMediaQuery } from "react-responsive"
import axios from "axios"
import NumberFormat from "react-number-format"
import moment from "moment"
import BackButton from "../Global/BackButton"
import { ArrowUpRight, ArrowDownRight } from "tabler-icons-react"
import { ResponsiveContainer, Bar, Line, ComposedChart, XAxis, YAxis, Tooltip } from "recharts"
import "react-modern-calendar-datepicker/lib/DatePicker.css"
import DatePicker from "react-modern-calendar-datepicker"

const InsightsDashboard = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
	const [insights, setInsights] = useState([])
	const [minMaxDate, setMinMaxDate] = useState()
	const [gridDayRef, setGridDayRef] = useState(true)

	const getInsights = (postData) => {
		const url = `${process.env.REACT_APP_API_URL}Insights/GetInsights.php`

		const api = axios.create()
		api.post(url, postData).then((response) => {
			// console.log(response.data)
			// console.log(response.data.gridDay[0].months[0])
			const revenueByMonth = []
			const [minYear, minMonth, minDay, maxYear, maxMonth, maxDay] = [
				...response.data.minMaxDate.from.split("-"),
				...response.data.minMaxDate.to.split("-"),
			]
			const fromTo = response.data.fromTo ? response.data.fromTo : response.data.minMaxDate
			setMinMaxDate({
				minDate: { year: minYear, month: minMonth, day: minDay, formatted: fromTo.from },
				maxDate: { year: maxYear, month: maxMonth, day: maxDay, formatted: fromTo.to },
			})
			
			response.data.gridDay.map((year) => revenueByMonth.push(...year.months[0]))
			// console.log(revenueByMonth)
			setInsights({ ...response.data, revenueByMonth: revenueByMonth })
		})
	}

	useEffect(() => {
		getInsights()
	}, [])

	function CustomTooltip({ payload, label, active }) {
		return active ? (
			<div className="rechart-tooltip">
				<p className="title">{label}</p>
				<p className="detail">
					CA Total :{" "}
					{
						<NumberFormat
							value={payload[0]?.payload?.totalRevenue || 0}
							decimalScale={2}
							displayType={"text"}
							decimalSeparator={","}
							fixedDecimalScale={true}
							suffix={"€"}
							thousandSeparator={" "}
						/>
					}
				</p>
				<p className="detail">Nombre de jour : {payload[0]?.payload?.totalDays}</p>
				<p className="detail">
					Moyenne :{" "}
					{
						<NumberFormat
							value={payload[0]?.value || 0}
							decimalScale={2}
							displayType={"text"}
							decimalSeparator={","}
							fixedDecimalScale={true}
							suffix={"€"}
							thousandSeparator={" "}
						/>
					}
				</p>
			</div>
		) : null
	}

	function TooltipByMonth({ payload, label, active }) {
		return active ? (
			<div className="rechart-tooltip">
				<p className="title">{moment(label, "M/YYYY").format("MMMM YYYY")}</p>
				<p className="detail">
					CA Total :{" "}
					{
						<NumberFormat
							value={payload[0].payload.totalRevenue || 0}
							decimalScale={2}
							displayType={"text"}
							decimalSeparator={","}
							fixedDecimalScale={true}
							suffix={"€"}
							thousandSeparator={" "}
						/>
					}
				</p>
				<p className="detail">
					CA moyen :{" "}
					{
						<NumberFormat
							value={payload[0].payload.totalRevenueAvg || 0}
							decimalScale={2}
							displayType={"text"}
							decimalSeparator={","}
							fixedDecimalScale={true}
							suffix={"€"}
							thousandSeparator={" "}
						/>
					}
				</p>
			</div>
		) : null
	}

	const daysCell = []
	for (var i = 1; i <= 31; i++) {
		daysCell.push(
			<div key={i} id={`cell-${i}`}>
				{i}
			</div>
		)
	}

	const displayDayCell = (data) => {
		const dayCell = []
		for (var i = 1; i <= 31; i++) {
			const dayExist = data.find((day) => parseInt(day.day) === i)
			dayExist
				? dayCell.push(
						<div className={`grid-day percentil-${dayExist.percentil}`} key={i}>
							<div className="grid-day-tooltip">
								<p>{moment(dayExist?.date).format("dddd D MMMM YYYY")}</p>
								<NumberFormat
									value={dayExist.revenue || 0}
									decimalScale={2}
									displayType={"text"}
									decimalSeparator={","}
									fixedDecimalScale={true}
									suffix={"€"}
									thousandSeparator={" "}
								/>
							</div>
						</div>
				  )
				: dayCell.push(<div className="grid-day" key={i}></div>)
		}
		return dayCell
	}
	// Charts
	const monthTickFormatter = (tick) => {
		return moment(tick, "M/YYYY").format("MM/YY")
	}

	// From to input
	const [selectedDayRange, setSelectedDayRange] = useState({
		from: null,
		to: null,
	})
	const [fromTo, setFromTo] = useState({})

	useEffect(() => {
		const readableDate = {
			from:
				selectedDayRange.from &&
				moment(`${selectedDayRange.from.day}/${selectedDayRange.from.month}/${selectedDayRange.from.year}`, "D/M/YYYY").format("DD/MM/YYYY"),
			to:
				selectedDayRange.to &&
				moment(`${selectedDayRange.to.day}/${selectedDayRange.to.month}/${selectedDayRange.to.year}`, "D/M/YYYY").format("DD/MM/YYYY"),
		}
		const sqlQueryDate = {
			from:
				selectedDayRange.from &&
				moment(`${selectedDayRange.from.day}/${selectedDayRange.from.month}/${selectedDayRange.from.year}`, "D/M/YYYY").format("YYYY-MM-DD"),
			to:
				selectedDayRange.to &&
				moment(`${selectedDayRange.to.day}/${selectedDayRange.to.month}/${selectedDayRange.to.year}`, "D/M/YYYY").format("YYYY-MM-DD"),
		}
		setFromTo({ selectedDayRange, readableDate, sqlQueryDate })
	}, [selectedDayRange])

	return (
		<section className="section size-6">
			<header className="header sticky">
				<h2>{isMobile && <BackButton />} Insights</h2>
			</header>

			<DatePicker value={selectedDayRange} onChange={setSelectedDayRange} maximumDate={minMaxDate?.maxDate} minimumDate={minMaxDate?.minDate} />
			<button onClick={() => getInsights({ fromTo: fromTo.sqlQueryDate })}>Actualiser</button>
			{fromTo.readableDate?.to && (
				<p>
					Du {fromTo.readableDate.from} au {fromTo.readableDate.to}
				</p>
			)}

			<h3>Global</h3>
			<p className="stats">
				CA depuis l'ouverture :{" "}
				<NumberFormat
					value={insights?.ca?.revenue || 0}
					decimalScale={2}
					displayType={"text"}
					decimalSeparator={","}
					fixedDecimalScale={true}
					suffix={"€"}
					thousandSeparator={" "}
				/>{" "}
				soit un bénéfice total de{" "}
				<NumberFormat
					value={insights?.ca?.gain || 0}
					decimalScale={2}
					displayType={"text"}
					decimalSeparator={","}
					fixedDecimalScale={true}
					suffix={"€"}
					thousandSeparator={" "}
				/>
			</p>
			<p className="stats">
				CA moyen / jour ouvert :{" "}
				<NumberFormat
					value={insights?.ca?.revenueAvg || 0}
					decimalScale={2}
					displayType={"text"}
					decimalSeparator={","}
					fixedDecimalScale={true}
					suffix={"€"}
					thousandSeparator={" "}
				/>{" "}
				soit un bénéfice moyen par jour de{" "}
				<NumberFormat
					value={insights?.ca?.gainAvg || 0}
					decimalScale={2}
					displayType={"text"}
					decimalSeparator={","}
					fixedDecimalScale={true}
					suffix={"€"}
					thousandSeparator={" "}
				/>
			</p>
			<p className="stats">
				Jours ouverts : {insights?.ca?.openedDays} /{" "}
				{moment(minMaxDate?.maxDate?.formatted).diff(moment(minMaxDate?.minDate?.formatted), "days") + 1}
			</p>
			<p className="stats">
				Jours fermés :{" "}
				{moment(minMaxDate?.maxDate?.formatted).diff(moment(minMaxDate?.minDate?.formatted), "days") + 1 - insights?.ca?.openedDays} /{" "}
				{moment(minMaxDate?.maxDate?.formatted).diff(moment(minMaxDate?.minDate?.formatted), "days") + 1}
			</p>
			<h3>Record depuis l'ouverture</h3>
			<p className="stats">
				Max : {moment(insights?.maxRevenue?.day).format("dddd D MMMM YYYY")} -{" "}
				<NumberFormat
					value={insights?.maxRevenue?.revenue || 0}
					decimalScale={2}
					displayType={"text"}
					decimalSeparator={","}
					fixedDecimalScale={true}
					suffix={"€"}
					thousandSeparator={" "}
				/>
			</p>
			<p className="stats">
				Min : {moment(insights?.minRevenue?.day).format("dddd D MMMM YYYY")} -{" "}
				<NumberFormat
					value={insights?.minRevenue?.revenue || 0}
					decimalScale={2}
					displayType={"text"}
					decimalSeparator={","}
					fixedDecimalScale={true}
					suffix={"€"}
					thousandSeparator={" "}
				/>
			</p>

			<h3>Moyenne par jour de la semaine</h3>
			<ResponsiveContainer width="70%" height={200}>
				<ComposedChart data={insights.byWeekDay} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
					<XAxis dataKey="weekDay" stroke="#8c8c8c" />
					<YAxis dataKey="average" stroke="#8c8c8c" domain={["dataMin", "auto"]} />
					<Tooltip content={<CustomTooltip />} />
					<Bar dataKey="average" fill="#8884d8" />
				</ComposedChart>
			</ResponsiveContainer>

			<h3>Évolution du CA par mois</h3>
			<ResponsiveContainer width="95%" height={200}>
				<ComposedChart data={insights.revenueByMonth} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
					<Tooltip content={<TooltipByMonth />} />

					<XAxis dataKey="monthDate" stroke="#8c8c8c" tickFormatter={monthTickFormatter} />

					<YAxis yAxisId="total" dataKey="totalRevenue" stroke="#8c8c8c" />
					<Bar yAxisId="total" dataKey="totalRevenue" fill="#8884d8" />

					<YAxis yAxisId="average" type="number" dataKey="totalRevenueAvg" stroke="#a75fa6" orientation="right" />
					<Line yAxisId="average" type="monotone" dataKey="totalRevenueAvg" stroke="#a75fa6" />
				</ComposedChart>
			</ResponsiveContainer>

			<h3>CA par jour</h3>
			<div className="grid-days">
				<div className="grid-days-head">
					<div>&nbsp;</div>
					<div className="span2">
						<ul className="pills">
							<li onClick={() => setGridDayRef(true)} className={gridDayRef ? "active" : ""}>
								Total
							</li>
							<li onClick={() => setGridDayRef(false)} className={gridDayRef ? "" : "active"}>
								Moyenne
							</li>
						</ul>
					</div>
					{/* <div>&nbsp;</div> */}
					{daysCell}
				</div>
				{insights?.gridDay?.map((year) =>
					year?.months?.map((months) =>
						months?.map((month, key) => (
							<div className="grid-days-body" key={key}>
								<p className="grid-days-month month">{moment(`${month.month}-${year.year}`, "M-YYYY").format("MMM YYYY")}</p>
								<p className="grid-days-month total-month">
									<NumberFormat
										value={(gridDayRef ? month.totalRevenue : month.totalRevenueAvg) || 0}
										decimalScale={0}
										displayType={"text"}
										decimalSeparator={","}
										fixedDecimalScale={true}
										suffix={"€"}
										thousandSeparator={" "}
									/>
								</p>
								<p className="grid-days-month evolution">
									{(gridDayRef ? month.evolution : month.evolutionAvg) > 0 ? (
										<ArrowUpRight size={20} color="#83d46f" />
									) : (
										<ArrowDownRight size={20} color="#e4625d" />
									)}
									<NumberFormat
										value={Math.abs(gridDayRef ? month.evolution : month.evolutionAvg) || 0}
										decimalScale={0}
										displayType={"text"}
										decimalSeparator={","}
										fixedDecimalScale={true}
										suffix={"%"}
										thousandSeparator={" "}
									/>
								</p>

								{displayDayCell(month.days)}
							</div>
						))
					)
				)}
			</div>
		</section>
	)
}

export default InsightsDashboard
