import React from 'react'
import { Search } from "tabler-icons-react"

const SearchBar = (props) => {
    return (
		<div className="search-bar">
			<Search size={20} color="#8C8C8C" />
			<input type="text" name="" value={props.Value} onChange={props.onChange} placeholder={props.Placeholder} />
			{props.Value.length > 0 && <button onClick={props.clearSearch}>x</button>}
		</div>
	)
}

export default SearchBar