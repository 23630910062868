import React, { useState, useContext, useEffect } from "react"
import axios from "axios"
import "./Product.scss"
import { useDropzone } from "react-dropzone"
import { useParams, useNavigate } from "react-router-dom"
import FlagFrench from "../../Assets/Icons/flag-french.jpg"
import FlagChina from "../../Assets/Icons/flag-china.jpg"
import FlagThai from "../../Assets/Icons/flag-thai.jpg"
import FlagEnglish from "../../Assets/Icons/flag-english.jpg"
import { useAlert } from "../../Context/Alert.Provider"
import StaticContext from "../../Context/Static.Provider"
import Buttons from "../Global/Buttons"
import { Download } from "tabler-icons-react"
import Select from "react-select"

const ProductForm = (props) => {
	let navigate = useNavigate()
	
	// Fetch static product context data
	const [allergiesList, setAllergiesList] = useState([])
	const [categoriesList, setCategoriesList] = useState([])
	const [packagingsList, setPackagingsList] = useState([])
	const [meatsList, setMeatsList] = useState([])
	const [tagsList, setTagsList] = useState([]) // This form selected tags. Array with only tag id
	const [productTagsOptions, setProductTagsOptions] = useState([]) // All tags with full tag data
	const { statics } = useContext(StaticContext)

	useEffect(() => {
		window.scrollTo({ top: 0 })
	}, [])
	
	useEffect(() => {
		setAllergiesList(statics.allergies)
		setCategoriesList(statics.categories)
		setPackagingsList(statics.packagings)
		setMeatsList(statics.meats)
		setProductTagsOptions(statics.tags)
	}, [statics])

	// Get the custom hook from Alert.Provider.js
	const newAlert = useAlert()

	let { id } = useParams()

	const [newProduct, setNewProduct] = useState({ allergy: [], taxe: 10 })

	useEffect(() => {
		// If edition mode, fetch product data with id
		if (id) {
			const url = `${process.env.REACT_APP_API_URL}Products/GetProducts.php`

			const api = axios.create()
			api.post(url, { id: id }).then((response) => {
				// Translate database tags [1, 2] into selected input pre selected options
				setTagsList(response.data[0]?.tag ? productTagsOptions?.filter((tag) => response.data[0]?.tag?.indexOf(tag.id) !== -1) : [])
				setNewProduct(response.data[0])
			})
		}
	}, [id, productTagsOptions])

	// Get all current change from inputs className={'hello'}
	const handleChange = (e) => {
		e.target.name
			? setNewProduct({ ...newProduct, [e.target.name]: e.target.value.length > 0 ? e.target.value : "" })
			: setNewProduct({ ...newProduct, [e.target.dataset.name]: e.target.dataset.value })
	}

	// Set/Unset multiple checkbox
	const handleChangeMultiple = (e) => {
		const newEntry = newProduct[e.target.dataset.name]
			? newProduct[e.target.dataset.name].indexOf(e.target.dataset.value) !== -1
				? newProduct[e.target.dataset.name].filter((el) => el !== e.target.dataset.value)
				: [...newProduct[e.target.dataset.name], e.target.dataset.value]
			: [e.target.dataset.value]
		setNewProduct({
			...newProduct,
			[e.target.dataset.name]: newEntry,
		})
	}

	const handleSelect = (e) => {
		setTagsList(e)
		setNewProduct({ ...newProduct, tag: e.map((tag) => tag.id) })
	}

	// Send new product data to controller
	const sendProduct = (e) => {
		e.preventDefault()

		const api = axios.create()
		// If edit mode, use EditProduct controller else use AddProduct controller
		let url = id ? `${process.env.REACT_APP_API_URL}Products/EditProduct.php` : `${process.env.REACT_APP_API_URL}Products/AddProduct.php`
		api.post(url, newProduct).then((response) => {
			newAlert({
				type: "success",
				message: id
					? `Le produit <b>${newProduct.name_fr}</b> a bien été modifié`
					: `Le produit <b>${newProduct.name_fr}</b> a bien été ajouté`,
			})
			navigate(`/products`)
		})
	}

	const deleteProduct = (e) => {
		e.preventDefault()

		const api = axios.create()
		// Delete product
		let url = `${process.env.REACT_APP_API_URL}Products/EditProduct.php`
		api.post(url, { id: id, action: "deleteProduct" }).then((response) => {
			newAlert({
				type: "success",
				message: `Le produit <b>${newProduct.name_fr}</b> a bien été supprimé`,
			})
			navigate(`/products`)
		})
	}

	// --------------- DROP ZONE ---------------
	const [files, setFiles] = useState([])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: "image/*",
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			)
			acceptedFiles.map((file) => {
				// Convert file to Base64 and add it to new product data
				var reader = new FileReader()
				const splitted = file.name.split(".")
				reader.readAsDataURL(file)
				reader.onloadend = function () {
					setNewProduct({ ...newProduct, image_name: file.name, base64: reader.result, extension: splitted[splitted.length - 1] })
				}
				return true
			})
		},
	})

	const images = files.map((file) => (
		<div key={file.name} className="file-preview">
			<img src={file.preview} alt=""></img>
			<p>{file.name}</p>
		</div>
	))

	return (
		<section className="section size-5">
			<header className="header sticky">
				<h2>{id ? `Éditer : ${newProduct.name_fr}` : "Nouveau plat"}</h2>
				<div className="header-actions">
					<Buttons size="huge" type="full" color="main" icon="save" iconSize={20} iconColor="white" handleClick={(e) => sendProduct(e)}>
						{id ? "Sauvegarder" : "Ajouter"}
					</Buttons>
				</div>
			</header>
			<form className="form product-form" onSubmit={(e) => sendProduct(e)}>
				<section className="col">
					<article className="row col-2">
						<div className="field-wrapper">
							<p className="field-label">Nom</p>
							<label className="product-form-name">
								<img src={FlagFrench} alt="" />
								<input
									type="text"
									className="field"
									name="name_fr"
									value={newProduct.name_fr || undefined}
									onChange={(e) => handleChange(e)}
								/>
							</label>
							<label className="product-form-name">
								<img src={FlagChina} alt="" />
								<input
									type="text"
									className="field"
									name="name_ch"
									value={newProduct.name_ch || undefined}
									onChange={(e) => handleChange(e)}
								/>
							</label>
							<label className="product-form-name">
								<img src={FlagThai} alt="" />
								<input
									type="text"
									className="field"
									name="name_th"
									value={newProduct.name_th || undefined}
									onChange={(e) => handleChange(e)}
								/>
							</label>
							<label className="product-form-name">
								<img src={FlagEnglish} alt="" />
								<input
									type="text"
									className="field"
									name="name_en"
									value={newProduct.name_en || undefined}
									onChange={(e) => handleChange(e)}
								/>
							</label>
						</div>
						<div className={`field-wrapper drop-zone ${images.length > 0 ? "reduce" : ""}`}>
							<p className="field-label">Photo</p>
							{newProduct.image && (
								<img src={`${process.env.REACT_APP_IMG_PATH}${newProduct.image}`} className="product-image" alt={props.image} />
							)}
							{/* // Drag & Drop zone for product image  */}
							<div>{images}</div>
							<div className={`drop-area ${isDragActive && "active"} ${newProduct.image && "small"}`} {...getRootProps()}>
								<input {...getInputProps()} className="drag-zone" />
								<Download size={20} color="#8C8C8C" />
								<p>Glisser-déposer ici</p>
							</div>
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Catégorie</p>
							<ul className="pills">
								{categoriesList &&
									categoriesList.map((cat) => (
										<li
											key={cat.id}
											className={newProduct.category === cat.id ? "active" : undefined}
											data-name="category"
											data-value={cat.id}
											onClick={(e) => handleChange(e)}>
											{cat.label}
										</li>
									))}
							</ul>
						</div>
					</article>
					<article className="row col-2">
						<div className="field-wrapper">
							<p className="field-label">Type</p>
							<ul className="pills">
								{packagingsList &&
									packagingsList.map((packaging) => (
										<li
											key={packaging.id}
											className={newProduct.packaging === String(packaging.id) ? "active" : undefined}
											data-name="packaging"
											data-value={packaging.id}
											onClick={(e) => handleChange(e)}>
											{packaging.label}
										</li>
									))}
							</ul>
						</div>
						{newProduct.packaging === "5" && (
							<div className="field-wrapper w25p">
								<p className="field-label">Custom type</p>
								<input
									type="text"
									className="field"
									name="custom_packaging"
									value={newProduct.custom_packaging || undefined}
									onChange={(e) => handleChange(e)}
								/>
							</div>
						)}
						<div className="field-wrapper w25p">
							<p className="field-label">Pièces</p>
							<input
								type="number"
								className="field"
								name="piece"
								value={newProduct.piece || undefined}
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</article>
					<article className="row col-2">
						<div className="field-wrapper field-price">
							<p className="field-label">Prix</p>
							<input
								type="number"
								className="field"
								name="price"
								value={newProduct.price || undefined}
								onChange={(e) => handleChange(e)}
							/>
						</div>
						<div className="field-wrapper field-taxes">
							<p className="field-label">TVA</p>
							<ul className="pills">
								<li
									className={parseInt(newProduct.taxe) === 10 ? "active" : undefined}
									data-name="taxe"
									data-value={10}
									onClick={(e) => handleChange(e)}>
									10%
								</li>
								<li
									className={parseInt(newProduct.taxe) === 20 ? "active" : undefined}
									data-name="taxe"
									data-value={20}
									onClick={(e) => handleChange(e)}>
									20%
								</li>
							</ul>
						</div>
					</article>
					<article className="row col-2">
						{/* <div className="field-wrapper">
							<p className="field-label">Stock</p>
							<input
								type="number"
								className="field"
								name="stock"
								value={newProduct.stock || undefined}
								onChange={(e) => handleChange(e)}
							/>
						</div> */}
						<div className="field-wrapper">
							<p className="field-label">Rupture de stock</p>
							<ul className="pills">
								<li
									className={parseInt(newProduct.out_of_stock) === 1 ? "active" : undefined}
									data-name="out_of_stock"
									data-value={parseInt(newProduct.out_of_stock) ? 0 : 1}
									onClick={(e) => handleChange(e)}>
									En rupture
								</li>
							</ul>
						</div>
						<div className="field-wrapper">
							<p className="field-label">Temps de préparation</p>
							<input
								type="number"
								className="field"
								name="cooking_time"
								value={newProduct.cooking_time || undefined}
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Viande</p>
							<ul className="pills">
								{meatsList?.map((meat) => (
									<li
										key={meat.id}
										className={newProduct.meat && newProduct.meat.indexOf(meat.id) !== -1 ? "active" : undefined}
										data-name="meat"
										data-value={meat.id}
										onClick={(e) => handleChangeMultiple(e)}>
										{meat.label}
									</li>
								))}
							</ul>
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Tags</p>
							<Select
								value={tagsList}
								data-name="tags"
								name="tags"
								onChange={(e) => handleSelect(e)}
								options={productTagsOptions}
								isMulti
								isSearchable
								placeholder="Choisir un tag"
							/>
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Allergènes</p>
							<ul className="pills">
								{allergiesList?.map((allergy) => (
									<li
										key={allergy.id}
										className={newProduct.allergy && newProduct.allergy.indexOf(allergy.id) !== -1 ? "active" : undefined}
										data-name="allergy"
										data-value={allergy.id}
										onClick={(e) => handleChangeMultiple(e)}>
										{allergy.label}
									</li>
								))}
							</ul>
						</div>
					</article>
					<article className="row">
						<div className="field-wrapper">
							<p className="field-label">Description</p>
							<textarea
								className="field textarea"
								name="description"
								rows="10"
								value={newProduct.description || undefined}
								onChange={(e) => handleChange(e)}></textarea>
						</div>
					</article>
					{/* <article className="row">
						<div className="field-wrapper">
							<p className="field-label">Fun fact</p>
							<textarea
								className="field textarea"
								name="fun_fact"
								rows="10"
								value={newProduct.fun_fact || undefined}
								onChange={(e) => handleChange(e)}></textarea>
						</div>
					</article> */}
					<Buttons size="huge" type="ghost" color="grey" icon="trash" iconSize={20} iconColor="grey" handleClick={(e) => deleteProduct(e)}>
						Supprimer
					</Buttons>
				</section>
			</form>
		</section>
	)
}

export default ProductForm
