import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import AlertProvider from "./Context/Alert.Provider.js"
import { StaticContextProvider } from "./Context/Static.Provider"

ReactDOM.render(
	<React.StrictMode>
		<AlertProvider>
			<StaticContextProvider>
				<App />
			</StaticContextProvider>
		</AlertProvider>
	</React.StrictMode>,
	document.getElementById("app")
)
