import React, { useState, useEffect } from "react"
import { useAlert } from "../../Context/Alert.Provider"
import axios from "axios"
import moment from "moment"
import SearchBar from "../Global/SearchBar"
import Buttons from "../Global/Buttons"
import StockTakings, { StockItems } from "./StockRow"
import "./Stocks.scss"

export const StocksDashboard = () => {
	const newAlert = useAlert()
	const [stockTakings, setStockTakings] = useState([])
	const [stockItems, setStockItems] = useState([])
	const [searchStockItems, setSearchStockItems] = useState("")
	const [formStockTaking, setFormStockTaking] = useState(false)
	const defaultStockTakingName = moment().format("MMMM YYYY").charAt(0).toUpperCase() + moment().format("MMMM YYYY").slice(1)
	const [newStockTaking, setNewStockTaking] = useState(defaultStockTakingName)

	const handleSearch = (e) => {
		setSearchStockItems(e.target.value)
	}

	// Get all stocktakings data
	const getStockTakings = () => {
		const url = `${process.env.REACT_APP_API_URL}Stocks/GetStockTakings.php`

		const api = axios.create()
		api.get(url).then((response) => {
			console.table(response.data)
			setStockTakings(response.data)
		})
	}
	useEffect(() => {
		getStockTakings()
	}, [])

	// Get all stockitems data
	const getStockItems = (search) => {
		const url = `${process.env.REACT_APP_API_URL}Stocks/GetStockItems.php`

		const api = axios.create()
		api.get(url).then((response) => {
			const filteredRes = response.data?.filter((item) => item.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1)
			setStockItems(filteredRes)
		})
	}

	useEffect(() => {
		getStockItems(searchStockItems)
	}, [searchStockItems])

	// Create a new stockTaking
	const handleSubmitNewStockTaking = () => {
		const url = `${process.env.REACT_APP_API_URL}Stocks/AddStockTaking.php`

		const api = axios.create()
		api.post(url, { name: newStockTaking }).then((response) => {
			newAlert({
				type: "success",
				message: `L'inventaire <b>${newStockTaking}</b> a bien été ajouté`,
			})
			getStockTakings()
			setFormStockTaking(false)
			setNewStockTaking(defaultStockTakingName)
		})
	}
	const [editStockTaking, setEditStockTaking] = useState({}) // [editId, editValue]

	const handleSubmitEditStockTaking = () => {
		const url = `${process.env.REACT_APP_API_URL}Stocks/EditStockTaking.php`

		const api = axios.create()
		api.post(url, { ...editStockTaking }).then((response) => {
			newAlert({
				type: "success",
				message: `L'inventaire <b>${editStockTaking.name}</b> a bien été modifié`,
			})
			getStockTakings()
			setEditStockTaking({})
		})
	}

	return (
		<section className="section size-6">
			<header className="header">
				<h2>Inventaires</h2>
				<div className="header-actions">
					<SearchBar
						onChange={(e) => handleSearch(e)}
						clearSearch={() => setSearchStockItems("")}
						Placeholder="Rechercher"
						Value={searchStockItems}
					/>
					<Buttons path="/stocks/item/new" size="huge" type="full" color="main" icon="plus" iconSize="20" iconColor="white">
						Ajouter
					</Buttons>
				</div>
			</header>

			{/* Stock taking list */}
			<div className="table stocktakings-tab">
				<div className="tab-row tab-header">
					<div className="tab-col">Inventaire</div>
					<div className="tab-col">Références</div>
					<div className="tab-col">Produits</div>
					<div className="tab-col">Actions</div>
				</div>
				{stockTakings?.map((item) => (
					<StockTakings
						key={item.stocktaking_id}
						item={item}
						editStockTaking={editStockTaking}
						setEditStockTaking={setEditStockTaking}
						handleSubmitEditStockTaking={() => handleSubmitEditStockTaking()}
					/>
				))}
				<div className="stocktaking-form">
					{formStockTaking ? (
						<>
							<input
								className="field"
								name="name"
								type="text"
								onChange={(e) => setNewStockTaking(e.target.value)}
								value={newStockTaking}
							/>
							<Buttons size="huge" type="full" color="main" handleClick={() => handleSubmitNewStockTaking()}>
								Créer
							</Buttons>
							<Buttons size="huge" type="full" color="grey" handleClick={() => setFormStockTaking(false)}>
								Annuler
							</Buttons>
						</>
					) : (
						<>
							<Buttons size="huge" type="full" color="main" handleClick={() => setFormStockTaking(true)}>
								Ajouter
							</Buttons>
						</>
					)}
				</div>
			</div>

			{/* Stock item list */}
			<div className="table stockitems-tab">
				<div className="tab-row tab-header">
					<div className="tab-col">Produit</div>
					<div className="tab-col">Code barre</div>
					<div className="tab-col">Actions</div>
				</div>
				{stockItems?.map((item) => (
					<StockItems key={item.stockitem_id} {...item} />
				))}
			</div>
		</section>
	)
}

export default StocksDashboard
