import React, { useEffect, useState, useCallback } from "react"
import IconCheck from "../../Assets/Icons/check.svg"
import IconWarning from "../../Assets/Icons/warning.svg"
import IconTimes from "../../Assets/Icons/times.svg"

const Alert = (props) => {
    const [exit, setExit] = useState(false)
    const [width, setWidth] = useState(10)
    const [intervalId, setIntervalId] = useState(null)

    const handlePauseTimer = useCallback(() => {
		clearInterval(intervalId)
	}, [intervalId])

    const handleCloseAlert = useCallback(() => {
		handlePauseTimer()
		setExit(true)
		setTimeout(() => {
			props.dispatch({
				type: "REMOVE_ALERT",
				id: props.id,
			})
		}, 500)
	}, [props])


    const handleStartTimer = useCallback(() => {
		const id = setInterval(() => {
			setWidth((prev) => {
				if (prev < 100) {
					return prev + 0.1
				}
				clearInterval(id)
				handleCloseAlert()
				return prev
			})
		}, 1)
		setIntervalId(id)
	}, [])

    useEffect(() => {
		width === 100 && handleCloseAlert()
	}, [width])

    useEffect(() => {
		handleStartTimer()
	}, [])
    
	return (
		<div className={`alert ${props.type} ${exit ? "exit" : ""}`} onMouseEnter={handlePauseTimer} onMouseLeave={handleStartTimer} onClick={() => handleCloseAlert()}>
			<div className="gauge" style={{ width: `${width}%` }}></div>
			<span>
				{props.type === "success" && <img src={IconCheck} alt="" />}
				{props.type === "warning" && <img src={IconWarning} alt="" />}
				{props.type === "error" && <img src={IconTimes} alt="" />}
			</span>
			<p dangerouslySetInnerHTML={{ __html: props.message }}></p>
		</div>
	)
}

export default Alert