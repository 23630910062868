import React, { useState, useEffect } from "react"
import axios from "axios"
import { useMediaQuery } from "react-responsive"
import SearchBar from "../Global/SearchBar"
import Buttons from "../Global/Buttons"
import CustomerItem from "./CustomerItem"
import CustomerCard from "./CustomerCard"
import "./Customers.scss"

export const CustomersDashboard = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

	const [customers, setCustomers] = useState([])
	const [searchCustomers, setSearchCustomers] = useState("")

	const handleSearch = (e) => {
		setSearchCustomers(e.target.value)
	}

	// Get all customers data
	const getCustomers = (search) => {
		const url = `${process.env.REACT_APP_API_URL}Customers/GetCustomers.php`

		const api = axios.create()
		api.get(url).then((response) => {
			const filteredRes = response.data?.filter((item) => item.address.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1)
			setCustomers(filteredRes)
		})
	}

	useEffect(() => {
		getCustomers(searchCustomers)
	}, [searchCustomers])

	return (
		<section className="section size-6">
			<header className="header">
				<h2>Clients</h2>
				<div className="header-actions">
					<SearchBar
						onChange={(e) => handleSearch(e)}
						clearSearch={() => setSearchCustomers("")}
						Placeholder="Rechercher"
						Value={searchCustomers}
					/>
					<Buttons path="/customers/new" size="huge" type="full" color="main" icon="plus" iconSize="20" iconColor="white">
						Ajouter
					</Buttons>
				</div>
			</header>
			{isMobile ? (
				<div className="customers-cards">
					{customers?.map((item) => (
						<CustomerCard key={item.customer_id} {...item} />
					))}
				</div>
			) : (
				<div className="table customers-tab">
					<div className="tab-row tab-header">
						<div className="tab-col">Adresse</div>
						<div className="tab-col">Ville</div>
						<div className="tab-col">Téléphone</div>
						<div className="tab-col">Paiement</div>
						<div className="tab-col">Actions</div>
					</div>
					{customers?.map((item) => (
						<CustomerItem key={item.customer_id} {...item} />
					))}
				</div>
			)}
		</section>
	)
}

export default CustomersDashboard
