import React, { useState } from "react"
import "./Login.scss"
import PropTypes from "prop-types"

const sha512 = (str) => {
    return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then((buf) => {
        return Array.prototype.map.call(new Uint8Array(buf), (x) => ("00" + x.toString(16)).slice(-2)).join("")
    })
}

const Login = ({ setToken }) => {
	const [password, setPassword] = useState()

	const handleSubmit = (e) => {
		e.preventDefault()

		sha512(password).then((x) => x === "2702339d6145cc022155bf5789d924490211bf9a5858b7c7d9625a844482f4f8bb268d75f0a836a377a3da4917ad25ccb17de46eca42bb856dcfcaf6e4c94cd4" && setToken(x))
	}

	return (
		<form className="login-form" onSubmit={handleSubmit}>
			<input className="field" type="password" placeholder="Mot de passe" onChange={(e) => setPassword(e.target.value)} autoFocus/>
			<button className="btn huge ghost white" type="submit">
				Submit
			</button>
		</form>
	)
}
export default Login

Login.propTypes = {
	setToken: PropTypes.func.isRequired,
}
