const ReportingFieldNumber = (props) => {
    return (
		<>
			<label htmlFor={props.labelFor} className="field-label number">
				<input
					type="number"
					step="1"
					className="field square"
					name={props.labelForNumber}
					onChange={props.onChange}
					defaultValue={props.valueNumber}
				/>
				<p>{props.label}</p>
			</label>
			<div className="amount-field">
				<input
					type="number"
					step="0.01"
					className="field"
					id={props.labelFor}
					name={props.labelFor}
					onChange={props.onChange}
					defaultValue={props.value}
				/>
			</div>
		</>
	)
}

export default ReportingFieldNumber