import React, { useState, useEffect, useContext } from "react"
import StaticContext from "../../Context/Static.Provider"
import Buttons from "../Global/Buttons"
import Switcher from "../Global/Switcher"
import { Link } from "react-router-dom"

const ProductItem = (props) => {
	const [category, setCategory] = useState("-")
	const [packaging, setPackaging] = useState("-")
	const [tags, setTags] = useState([])
	const { statics } = useContext(StaticContext)

	useEffect(() => {
		props.category && setCategory(statics.categories?.find((category) => category.id === props.category)?.label)
		props.packaging && setPackaging(statics.packagings?.find((packaging) => packaging.id === props.packaging).label)
		props.tag && setTags(statics.tags?.filter((tag) => props.tag.indexOf(tag.id) !== -1))
	}, [statics, props])

	return (
		<div className="tab-row">
			{/* <p className="col tab-body">{props.image ? <img src={`${process.env.REACT_APP_IMG_PATH}${props.image}`} className="product-image" alt={props.image} /> : <img src="https://fakeimg.pl/55x55/EDEDED/393e4a/" className="product-image" alt={props.image} />}</p> */}
			<div className="col tab-body">
				<p>{String(props.id).padStart(2, "0")}</p>
			</div>
			<div className="col tab-body">
				<p className="product_item_name">
					<Link to={`/products/edit/${props.id}`}>
						{props.name_fr}
						{props.cooking_time > 0 && <span className="lower_pill grey">{props.cooking_time}min</span>}
						{parseInt(props.out_of_stock) === 1 && <span className="lower_pill warning">Rupture</span>}
					</Link>
					{tags?.map((tag) => (
						<span key={tag.id} className={`lower_pill grey`}>
							{tag.label}
						</span>
					))}
				</p>
				{/* {props.name_ch && <p>{props.name_ch}</p>}
				{props.name_th && <p>{props.name_th}</p>}
				{props.name_en && <p>{props.name_en}</p>} */}
			</div>
			<p className="col tab-body">{category}</p>
			<p className="col tab-body">{(props.price && parseFloat(props.price).toFixed(2).replace(".", ",")) || "-"}€</p>
			<p className="col tab-body">{`${props.piece > 1 ? props.piece : ""} ${
				parseInt(props.packaging) === 5 ? props.custom_packaging : `${packaging}${props.piece > 1 ? "s" : ""}`
			}`}</p>
			{/* <p className="col tab-body"></p> */}
			<div className="col tab-body action">
				<Buttons
					size="ghost"
					type="noborder"
					color="main"
					icon="copy"
					iconSize="20"
					iconColor="grey"
					iconOnly={true}
					handleClick={() => props.handleCopy(props)}
				/>
				<Buttons
					path={`/products/edit/${props.id}`}
					size="ghost"
					type="noborder"
					color="main"
					icon="edit"
					iconSize="20"
					iconColor="grey"
					iconOnly={true}
				/>
				<Switcher isActive={props.status === "1"} handleUpdate={() => props.handleUpdateStatus(props)} />
			</div>
		</div>
	)
}

export default ProductItem
