import React from "react"
import "../../Styles/Buttons.scss"
import { Link } from "react-router-dom"
import { Trash, Edit, Plus, DeviceFloppy, Search, Copy, ChartBar } from "tabler-icons-react"

const Buttons = (props) => {
	let btnIcon = null
	switch (props.icon) {
		case "save":
			btnIcon = <DeviceFloppy size={props.iconSize} color={props.iconColor} />
			break
		case "edit":
			btnIcon = <Edit size={props.iconSize} color={props.iconColor} />
			break
		case "copy":
			btnIcon = <Copy size={props.iconSize} color={props.iconColor} />
			break
		case "trash":
			btnIcon = <Trash size={props.iconSize} color={props.iconColor} />
			break
		case "plus":
			btnIcon = <Plus size={props.iconSize} color={props.iconColor} />
			break
		case "search":
			btnIcon = <Search size={props.iconSize} color={props.iconColor} />
			break
		case "graph":
			btnIcon = <ChartBar size={props.iconSize} color={props.iconColor} />
			break
		default:
			break
	}

	return (
		<>
			{props.path ? (
				<Link to={props.path}>
					<button className={`${props.customClass ? props.customClass : ''} btn ${props.size} ${props.type} ${props.color} ${props.iconOnly && "icon_only"} `} onClick={props.handleClick}>
						{btnIcon && btnIcon}
						{props.children}
					</button>
				</Link>
			) : (
				<button className={`${props.customClass ? props.customClass : ''} btn ${props.size} ${props.type} ${props.color} ${props.iconOnly && "icon_only"} `} onClick={props.handleClick}>
					{btnIcon && btnIcon}
					{props.children}
				</button>
			)}
		</>
	)
}

export default Buttons
